import React, { useState, useEffect } from "react";
import {
  Link, Switch, Route
} from "react-router-dom";
import { Helmet } from "react-helmet";
// import { connect } from 'react-redux';

import NestedResults from "../reddit/nestedresults";
import ErrorBar from "../common/errorBar";

import { redditService } from '../../services/reddit';
import { savedSearchOperations } from "../../redux/saved"
import { trackEvent } from '../../utils/tracking';
import { getConversationCsvDict } from '../../utils/reddit';


const TrackedKeywordNew = ({
  history, location,
  savedSearch, currentUser,
  baseUrl, // needed b/c this gets shown on multiple potential screens
  results,
  fetching,
  keyword,
}) => {
  const sortOptions = ['Recent', 'Upvotes', 'Comments']
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  var resultsToShow = results;
  if (sortOption === "Upvotes"){
    resultsToShow = resultsToShow.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  } else if (sortOption === "Comments"){
    resultsToShow = resultsToShow.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  } else if (!sortOption || sortOption === "Recent"){
    // should be default
    resultsToShow = resultsToShow.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  }

  return (
    <React.Fragment>
      <Helmet><title>Conversations | Tracked | New</title></Helmet>
      <NestedResults fetching={fetching} results={resultsToShow} keyword={keyword}
        baseUrl={baseUrl + 'new/'} location={location}  history={history}
        currentUser={currentUser}
        showHeader={true} showAiMatches={savedSearch.match_criteria.include || savedSearch.match_criteria.exclude}
        sortOptions={sortOptions} sortOption={sortOption} setSortOption={setSortOption}
      />
    </React.Fragment>
  );
};

export default TrackedKeywordNew