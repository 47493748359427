import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Loader from "../../components/common/loader";
import Modal from "../common/modal";
import AnalysisClusters from "../language/analysisClusters";
import AnalysisQuestionAnswerResults from "../language/questionAnswerResults";

import { searchesService } from '../../services/searches';

// serves a simple plan selection modal with the subscriptions component
const ReportModal = ({ 
    reports,
    history,
    match,
    closeModal,
    getReportTitle,
    getReportType,
    getReportResultsTitle,
 }) => {
  const reportHash = match.params.hash;
  const report = reports.find(r => r.hash === reportHash);
  const [reportDetails, setReportDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReport();

    // if "?share=1" then click the "share-results" button
    if (window.location.search.includes('?share=1')) {
      document.getElementById('share-results').click();
    }
  }, [reportHash]);

  const fetchReport = () => {
    if (!reportHash) return;
    setLoading(true);
    searchesService.fetchAnalysis(reportHash, (response) => {
      setLoading(false);
      setReportDetails(response.data)
    }, (error) => {
      console.error(error)
      setLoading(false)
    });
  }

  return (
    <Modal close={closeModal} widthClass={`max-w-5xl bg-gray-900 ${report && getReportType(report) === 'Ask' ? 'max-w-7xl' : 'max-w-5xl'}`}>
      <Helmet><title>Account | Report</title></Helmet>

      {!report ? (
        <div>Report not found</div>
      ) : (
        <React.Fragment>
          <div className="flex items-center">
            <h3 className="text-lg leading-6 font-medium">
              {getReportType(report)}: {getReportTitle(report)}
    
              {report.params_results.subreddits ? (
                <div className="text-gray-500 mt-1 text-sm">
                  In {report.params_results.subreddits.split(',').map(sub => `r/${sub}`).join(', ')}
                </div>
              ) : ''}
            </h3>
            <button className="focus:outline-none ml-auto" onClick={closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
    
          <div className="mt-4 relative" disabled={!reportDetails}>
            {loading ? <div className="absolute inset-0 flex items-center justify-center"><Loader /></div> : ''}
            {getReportType(report) === 'Patterns' ? <AnalysisClusters analysis={reportDetails || report} /> : ''}
            {getReportType(report) === 'Ask' ? <AnalysisQuestionAnswerResults analysis={reportDetails || report}  /> : ''}
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default ReportModal;

