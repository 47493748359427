import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import FullStory from 'react-fullstory';
import { ToastContainer, Slide, toast } from 'react-toastify';

import Authentication from './components/common/authentication.jsx';
import PropsRoute from './components/common/propsroute.jsx';


import Login from './pages/auth/login.jsx';
// import Register from './pages/auth/register.jsx';
import BetaRegister from './pages/auth/beta.jsx';
import PasswordReset from './pages/auth/passwordReset.jsx';
import PasswordResetConfirm from './pages/auth/passwordResetConfirm.jsx';
import Reddit from './pages/reddit.jsx';
import Account from './pages/account/index.jsx';
import Audiences from './pages/audiences/index.jsx';
import Audience from './pages/audiences/audience.jsx';
import Conversations from './pages/conversations/index.jsx';
import Reports from './pages/reports/index.jsx';
import Search from './pages/search.jsx';
import Status from './pages/status.jsx';
// import Search from './pages/search/index.jsx';
import Help from './pages/guide/index.jsx';
// import Top40 from './pages/top/index.jsx';
import Trial from './pages/trial/trial.jsx';
import TrialFeedback from './pages/trial/feedback.jsx';
import LimitReached from './pages/trial/limitReached.jsx';
import Unsubscribe from './pages/email/unsubscribe.jsx';
import Welcome from './pages/welcome';
import NotFound404 from './pages/404.jsx';
// import SharedReport from './pages/share/report.jsx';
// import PublicSubreddit from './pages/subreddit/index.jsx';

import 'react-toastify/dist/ReactToastify.css';


const App = (props) => {

  return (
    <Router>
      <ToastContainer hideProgressBar transition={Slide}/>
      {process.env.NODE_ENV === 'production' ? (
        <FullStory org={'13F3GE'} />
      ) : ''}
      
      <Switch>
        {/* external pages */}
        <PropsRoute path="/login/" component={Login} passAuthProps={true}/> {/* either logged in or logged out auth props works */}
        {/*<PropsRoute path="/register/" component={Register} />*/}
        <PropsRoute path="/beta/:betaHash/" component={BetaRegister} />
        <PropsRoute path="/password-reset/" component={PasswordReset} />
        <PropsRoute path="/password-reset-confirm/" component={PasswordResetConfirm} />
        {/*<PropsRoute path="/share/:hash/" component={SharedReport} />
        <PropsRoute path="/r/:slug/" component={PublicSubreddit} />*/}
        <PropsRoute path="/trial-feedback/" component={TrialFeedback} authRequired={false}/>
        <PropsRoute path="/email-unsubscribe/" component={Unsubscribe} authRequired={false}/>

        {/* auth required routes */}
        <PropsRoute path="/search/" component={Search} authRequired={true} />

        {/*<PropsRoute path="/audiences/(new|info|curated|trending)/" component={Audiences} authRequired={true} />*/}
        <PropsRoute path="/audiences/" component={Audiences} authRequired={true} />
        <PropsRoute path="/audience/:hash/" component={Audience} authRequired={true} />

        {/* Required paid/active account */}
        <PropsRoute path="/reports/" component={Reports} authRequired={true} />
        <PropsRoute path="/conversations/" component={Conversations} authRequired={true} />
        <PropsRoute path="/reddit/" component={Reddit} authRequired={true} />

        <PropsRoute path="/welcome/" component={Welcome} authRequired={true} />
        <PropsRoute path="/help/" component={Help} authRequired={true} />
        <PropsRoute path="/account/" component={Account} authRequired={true} />
        <PropsRoute path="/trial/" component={Trial} authRequired={true} />
        <PropsRoute path="/limit-reached/" component={LimitReached} authRequired={true} />
        <PropsRoute path="/status/" component={Status} authRequired={true} />

        {/* redirects & catchall */}
        <Redirect exact path="/" to="/audiences/" />
        <Redirect exact path="/home/" to="/search/" />
        <PropsRoute path="/404/" component={NotFound404}/>
        <Redirect to="/404/" /> {/* catchall */}
      </Switch>
    </Router>
  );
}

export default App;

