import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import ErrorBar from "../common/errorBar";
import LoaderFancy from "../common/loaderfancy";
import ResultsAnalyzePatterns from "../../components/language/patterns";
import ResultsAnalyzeQuestion from "../../components/language/questionAnswer";
import FeaturePaywallWrapper from "../../components/account/featurePaywallWrapper"

import { searchesService } from '../../services/searches';


// analyzes filtered results for audeince search
const AudienceBrowseAsk = ({
  currentUser,
  results,
  filterParams,
  fetching,
  collection,
}) => {

  const [recentSearches, setRecentSearches] = useState([]);
  const [loadingRecentSearches, setLoadingRecentSearches] = useState(false);

  // effects
  useEffect(() => {
    // fetch recent searches in this audience
    fetchRecentSearches();
  }, []);

  const fetchRecentSearches = () => {
    setLoadingRecentSearches(true)
    searchesService.recentAnalysisSearches({'reports_bulk': ['ask'], 'collection': collection.hash, 'keyword': filterParams.keyword, 'page_size': 10}, (results) => {
      if (results && results.data.results){
        setRecentSearches(Array.from(new Set(results.data.results.map(s => s.params_analysis.ask_question)))) // just save unique questions
        setLoadingRecentSearches(false)
      }
    }, () => {
      setLoadingRecentSearches(false)
    })
  }

  return (
    <div className="p-4 overflow-y-auto h-full">
      <Helmet><title>Audience | Browse | Ask</title></Helmet>
      <FeaturePaywallWrapper featureKey={"ask"} className={'max-w-full'} currentUser={currentUser}>
        {results && results.length ? (
          <ResultsAnalyzeQuestion
            currentUser={currentUser}
            results={results}
            // limitOptions={[100, 200, 500]}
            filterParams={Object.assign(filterParams, {
              // 'limit': 200, // bump up the number of results
            })}
            recentSearches={recentSearches} refetchSearches={fetchRecentSearches}
            showTips={true}
            columnView={true}
            placeholder={`Ask a question about ${filterParams.keyword} in this audience...`}
            // emptyMessage={results && results.length < 100 ? `If <100 AI results for ${topic.name} found, try "More > Keyword Search".` : ''}
          />
        ) : fetching ? (
          <div className="p-4 overflow-y-auto h-full">
            <div className="h-48 relative">
              <LoaderFancy />
            </div>
          </div>
        ) : (
          <div className="p-6 bg-gray-800 rounded-lg border border-solid border-gray-700 text-white">
            <div className="">
              <h3 className="text-lg leading-6 font-medium">
                No results at this time...
              </h3>
            </div>
          </div>
        )}
      </FeaturePaywallWrapper>
    </div>
  );
 
  return (
    <div className="p-4 overflow-y-auto h-full">
      <Helmet><title>Audience | Browse | Patterns</title></Helmet>
      {results && results.length ? (
        <ResultsAnalyzePatterns
          currentUser={currentUser}
          results={results}
          limitOptions={[100, 200, 500]}
          filterParams={Object.assign(filterParams, {
            // 'limit': 200, // bump up the number of results
            'clusters_focus_keyword': filterParams.keyword && !filterParams.keyword.includes(',') ? filterParams.keyword : null, // focus cluster if there is just 1 keyword (so not kw-based suggestion)
          })}
        />
      ) : (
        <div className="p-6 bg-gray-800 rounded-lg border border-solid border-gray-700 text-white">
          <div className="">
            <h3 className="text-lg leading-6 font-medium">
              No results at this time...
            </h3>
          </div>
        </div>
      )}
    </div>
  );
}

export default AudienceBrowseAsk;

