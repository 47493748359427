import React, { useState } from "react"

import { 
  // PieChart, Pie,
  BarChart, Bar,
  // Cell,
  XAxis,
  // YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// shows chart for shared report views
const SharedReportViews = ({ timeseries, lightBackground, view }) => {

  if (!timeseries || !timeseries.days || !timeseries.days.length) return '';

  const timeseriesData = view === 'Month' ? timeseries.months : timeseries.days;
  const label = view === 'Month' ? 'Month' : 'Day';

  // custom tooltip used for timeseries chart!
  const CustomTooltip = ({ active, payload, min, max }) => {

    if (active && payload && payload.length) {
      const bin = payload[0];
      const data = bin.payload;

      return (
        <div className={`p-2 text-xs rounded-md ${lightBackground ? 'bg-gray-200 text-gray-600' : 'bg-gray-700 text-white'}`}>
          <div className="">
            {label}: {data.date}
          </div>
          <div className="">
            Views: {data.count}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="h-24 text-black pb-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={timeseriesData}
            margin={{}}
          >
            <Tooltip content={<CustomTooltip />} cursor={{
              stroke: lightBackground ? "rgba(75, 85, 99, 0.25)" : "rgb(75 85 99)",
              strokeWidth: 1,
              fill: lightBackground ? "#ecf0f1" : "rgb(31 41 55)"
            }}/>
            <Bar dataKey={"count"} stackId="a" fill={lightBackground ? "#bdc3c7" : "rgba(75, 85, 99, 0.75)"}/>
          </BarChart>
        </ResponsiveContainer>
        <div className={`text-white flex items-center text-xs border-t ${lightBackground ? 'border-gray-200' : 'border-gray-700'}`}>
          <div className="text-gray-500">{timeseriesData[0]['date']}</div>
          <div className="text-gray-500 ml-auto">
            {timeseriesData[timeseriesData.length - 1]['date']}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharedReportViews;
