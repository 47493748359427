import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import ReactTooltip from 'react-tooltip';
import { Popover, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import {
  InformationCircleIcon
} from '@heroicons/react/outline'

// import { QuestionTo } from '@questionto/react';
// import { FeedbackFish } from '@feedback-fish/react'

import ChecklistPopover from "../components/guide/checklistPopover"

import { withRouter } from "react-router";
import { authService } from "../services/auth";

import { savedSearchOperations } from "../redux/saved"
import { statusOperations } from "../redux/status"

import { trackEvent } from '../utils/tracking';


const Sidebar = ({
  location, currentUser,
  trackedKeywordUnreadCount, fetchedUnreadCounts, fetchingTrackedKeywords, fetchAllSavedSearches,
  status, fetchingStatus, fetchStatus
}) => {

  useEffect(() => {
    // fetch unread counts if we havent already
    if (!fetchedUnreadCounts && !fetchingTrackedKeywords){
      fetchAllSavedSearches();
    }

    if (currentUser && currentUser.features && currentUser.features.status && (status === null) && !fetchingStatus){
      // fetch only when status is null b/c the sidebar is re-rendered on every main route change
      fetchStatus()
    }
  }, []);

  // service status
  var statusColor = null;
  var showStatusAlert = false
  if (status && !status.backend_primary){
    statusColor = 'rgb(239, 68, 68)'
    showStatusAlert = true
  } else if (status && status.backend_primary && !status.backend_secondary){
    statusColor = 'rgb(245, 158, 11)'
    showStatusAlert = true
  }

  // const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  // const isEffectiveProSubscriber = isProSubscriber || (currentUser && currentUser.trial && currentUser.trial.plan === 'pro');

  // shouldnt really get this
  if (!currentUser) return;

  return (
    <nav aria-label="Sidebar" className="hidden md:flex flex-col md:flex-shrink-0 md:bg-gray-800">
      <ReactTooltip id='sidebar' effect="solid" backgroundColor="white" textColor="black" />

      <div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
        <Link to="/audiences/" className="flex items-center justify-center h-20 w-20 bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600 md:w-20">
          <img className="h-10 w-auto" src="/images/branding-logo-head-gray.png" alt="GummySearch logo" />
        </Link>
      </div>

      <div className="relative w-20 flex flex-col p-3 py-4 space-y-3 flex-grow">

        <Link to="/audiences/" data-for='sidebar' data-tip="Audiences" className={`flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg ${location.pathname.startsWith('/audiences/') || location.pathname.startsWith('/audience/') ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
          <span className="sr-only">Audiences</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg>
        </Link>

        <Link to="/search/" data-for='sidebar' data-tip="Advanced Search" className={`flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg ${location.pathname.startsWith('/search/') || location.pathname.startsWith('/reddit/') ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
          <span className="sr-only">Search</span>
          {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>*/}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </Link>

        <Link to="/conversations/" data-for='sidebar' data-tip="Conversations" className={`flex-shrink-0 inline-flex relative items-center justify-center h-14 w-14 rounded-lg ${location.pathname.startsWith('/conversations/') ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
          <span className="sr-only">Conversations</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
          </svg>
          {/* <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg> */}

          {trackedKeywordUnreadCount > 0 ? (
            <React.Fragment>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 absolute top-2 right-2 text-teal-500" fill="#06b6d4" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="w-5 absolute top-2.5 right-2 text-black text-center text-xs" style={{fontSize: '9px'}}>{trackedKeywordUnreadCount}</span>
            </React.Fragment>
          ) : ''}
        </Link> 

        <Link to="/reports/" data-for='sidebar' data-tip="AI Reports" className={`flex-shrink-0 inline-flex relative items-center justify-center h-14 w-14 rounded-lg ${location.pathname.startsWith('/reports/') ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
          <span className="sr-only">AI Reports</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
          </svg>

        </Link> 
      </div>
      <div className="relative w-20 flex flex-col p-3 space-y-3">

        {/*<QuestionTo projectId="7mx2uar553l52t8" userId={currentUser && currentUser.identity.id}>
          <div data-for='sidebar' data-tip="Feedback" className={`flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg text-gray-400 hover:bg-gray-700 cursor-pointer`}>
            <span className="sr-only">Feedback</span>
            <svg className="h-6 w-6 pointer-events-none" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
            </svg>
          </div>
        </QuestionTo>*/}

        {/* <ChecklistPopover currentUser={currentUser} /> */}
        
        <Link to="/help/" data-for='sidebar' data-tip={`Help`} className={`flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg ${location.pathname.startsWith('/help/') ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
          <span  className="sr-only">Help</span>
          <InformationCircleIcon className="h-6 w-6" aria-hidden="true" />
        </Link>

        {/*{currentUser && currentUser.subscription && currentUser.subscription.stripe_customer_id ? (
          <React.Fragment>
            <Helmet>
              <script id="viralbox-widget" type="text/javascript" src="https://app.viralbox.co/widget.js" data-organizationid="e54ddadc-ab90-4f98-bcf8-0a716f367f85"
                data-stripecustomerid={currentUser.subscription.stripe_customer_id}></script>
            </Helmet>
            <div id="viralbox-panel-trigger"
              onClick={() => trackEvent('ClickedButton', {'type': 'ViralBox'})}
              data-for='sidebar' data-tip={`Rewards`} className={`flex-shrink-0 inline-flex cursor-pointer items-center justify-center h-14 w-14 rounded-lg ${false ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
              <span className="sr-only" className="sr-only">Rewards</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
              </svg>
            </div>
          </React.Fragment>
        ) : ''}*/}

        <Link to="/account/" data-for='sidebar' data-tip={`Account`} className={`flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg ${location.pathname.startsWith('/account/') ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
          <span className="sr-only">Account</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </Link>

        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`flex-shrink-0 inline-flex items-center justify-center h-14 w-14 focus:outline-none focus:ring-transparent focus:border-none rounded-lg ${open ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}
              >
                <span className="sr-only">More</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                </svg>
                {statusColor && showStatusAlert ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 absolute top-2 right-1 text-gray-400" style={{color: statusColor}} fill={statusColor} viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                ) : ''}
              </Popover.Button>

              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute z-10 left-20 bottom-0 mt-3 px-2 w-screen max-w-xs sm:px-0"
                >
                  <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-white p-4">

                      {/*<Link to="/welcome/"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11" />
                        </svg>
                        <div className="ml-4">
                          <p className="font-medium text-gray-700">Welcome Guide</p>
                        </div>
                      </Link>*/}

                      {currentUser ? (
                        <React.Fragment>

                          {/*{currentUser.trial && currentUser.trial.exists ? (
                            <Link to={`/trial/`}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                              </svg>
                              <div className="ml-4 flex items-center">
                                <p className="font-medium text-gray-700">About Free Trial</p>
                              </div>
                            </Link>
                          ) : ''}*/}


                          {/*<a href={`${currentUser.env.landing_page_url}/roadmap/`} target="_blank"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                            </svg>
                            <div className="ml-4">
                              <p className="font-medium text-gray-700">Product Roadmap</p>
                            </div>
                          </a>*/}
                          <a href={`${currentUser.env && currentUser.env.landing_page_url}/blog/`} target="_blank"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path d="M12 14l9-5-9-5-9 5 9 5z" />
                              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                            </svg>
                            <div className="ml-4">
                              <p className="font-medium text-gray-700">Blog & Examples</p>
                            </div>
                          </a>

                          {currentUser.subscription && currentUser.subscription.key ? (
                            <React.Fragment>
                              {false ? (
                                  <a href={`${currentUser.env.landing_page_url}/roadmap/`} target="_blank"
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                                  </svg>
                                  <div className="ml-4">
                                    <p className="font-medium text-gray-700">Product Roadmap</p>
                                  </div>
                                </a>
                              ) : ''}
                              <a href={`${currentUser.env.landing_page_url}/releases/`} target="_blank"
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
                                </svg>
                                <div className="ml-4">
                                  <p className="font-medium text-gray-700">Release Log</p>
                                </div>
                              </a>
                            </React.Fragment>
                          ) : ''}

                          <a href={`https://gummysearch.feedbear.com/boards/feature-requests`} target="_blank"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                            </svg>
                            <div className="ml-4">
                              <p className="font-medium text-gray-700">Feature Request</p>
                            </div>
                          </a>

                          <a href={`https://gummysearch.com/affiliates/`} target="_blank"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                            <div className="ml-4">
                              <p className="font-medium text-gray-700">Affiliate Dashboard</p>
                            </div>
                          </a>

                          {currentUser.identity && currentUser.identity.affiliate_link ? (
                            <div 
                              className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              onClick={() => {
                                copy(currentUser.identity.affiliate_link);
                                toast.success("Copied affiliate link to clipboard")
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z" clipRule="evenodd" />
                              </svg>
                              <div className="ml-4">
                                <p className="font-medium text-gray-700">Copy Affiliate Link</p>
                              </div>
                            </div>
                          ) : ''}

                          {currentUser.features && currentUser.features.status ? (
                            <Link to={`/status/`}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                              </svg>
                              <div className="ml-4 flex items-center">
                                <p className="font-medium text-gray-700">Service Status</p>

                                {statusColor && showStatusAlert ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 text-gray-400" style={{color: statusColor}} fill={statusColor} viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                ) : ''}
                              </div>
                            </Link>
                          ) : ''}

                          {currentUser.identity && currentUser.identity.is_staff ? (
                            <a href={`${currentUser.env.api_root_url}/admin/`}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                              </svg>
                              <div className="ml-4 flex items-center">
                                <p className="font-medium text-gray-700">Admin Panel</p>
                              </div>
                            </a>
                          ) : ''}
                        </React.Fragment>
                      ) : ''}

                    </div>
                    
                    <div className="px-4 py-4 bg-gray-50">
                      <div className="text-xs">
                        
                        {currentUser && currentUser.env ? (
                          <React.Fragment>
                            <a href={`${currentUser.env.landing_page_url}/terms-and-conditions/`} target="_blank"
                              className="block uppercase font-medium text-gray-600 hover:text-gray-800 transition ease-in-out duration-150"
                            >
                              Terms of Use
                            </a>
                            <a href={`${currentUser.env.landing_page_url}/privacy-policy/`} target="_blank"
                              className="block uppercase mt-2 font-medium text-gray-600 hover:text-gray-800 transition ease-in-out duration-150"
                            >
                              Privacy Policy
                            </a>
                          </React.Fragment>
                        ) : ''}
                        
                        <div onClick={() => authService.logout()}
                          className="block cursor-pointer uppercase mt-2 font-medium text-gray-600 hover:text-gray-800 transition ease-in-out duration-150"
                        >
                          Log Out →
                        </div>
                      </div>
                    </div>

                    {/*<div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                      <div>
                        <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">Recent Posts</h3>
                        <ul className="mt-4 space-y-4">
                          {recentPosts.map((post) => (
                            <li key={post.id} className="text-base truncate">
                              <a
                                href={post.href}
                                className="font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                              >
                                {post.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-5 text-sm">
                        <a
                          href="#"
                          className="font-medium text-gray-600 hover:text-gray-500 transition ease-in-out duration-150"
                        >
                          {' '}
                          View all posts <span aria-hidden="true">&rarr;</span>
                        </a>
                      </div>
                    </div>*/}
                    
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </nav>
  )
}

const mapStateToProps = state => {
  const {
    fetchedUnreadCounts,
    trackedKeywordUnreadCount,
    fetchingTrackedKeywords,
  } = state.savedSearch;
  const {
    status, fetching,
  } = state.status;

  return {
    fetchedUnreadCounts,
    trackedKeywordUnreadCount,
    fetchingTrackedKeywords,

    'status': status,
    'fetchingStatus': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAllSavedSearches = (callback, onError) => {
    dispatch(savedSearchOperations.fetchAllSavedSearches(callback, onError))
  };
  const fetchStatus = (callback) => {
    dispatch(statusOperations.fetchStatus(callback))
  };
  return {
    fetchAllSavedSearches,
    fetchStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));

