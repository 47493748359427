import React, { useState } from "react"
import Toggle from 'react-toggle'
import { Link } from "react-router-dom";
import SelectPopover from "../common/selectPopover";

import {
  CheckIcon, MailIcon,
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

import ErrorBoundary from "../common/errorboundary";

import TrackedKeywordRow from "./trackedkeywordrow"


const TrackedKeywordsList = ({
  currentUser,
  history,
  savedSearches,
  velocities,
  collections,
  refetch,
  updatingHashes,
  loading,
  urlRoot,  // set if using this component in audience
  hideGroup, showGrid, hideAudienceLabel
}) => {
  // state
  var groupings = ['Matches', 'Audience', 'Keyword', 'Type'];
  const [grouping, setGrouping] = useState(groupings[0]);  // TODO: when switching, dont refetch the subs
  const [includeStats, setIncludeStats] = useState(false);

  if (savedSearches && savedSearches.filter(s => s.label).length > 0){
    groupings.push('Label')
  }

  if (savedSearches && savedSearches.filter(s => s.notification_channel_id).length > 0){
    groupings.push('Channel')
  }

  // actions

  // display utils
  const getCollectionNameFromHash = (hash) => {
    // from the loaded collections we've got, get the name
    const collection = collections.filter(c => c.hash === hash)[0];
    if (collection){
      return collection.name
    }
    return '...'
  }

  // group the saved searches based on the grouping
  var groups = [];
  if (grouping === 'Matches'){
    if (savedSearches.filter(s => s.count_unread_ai_matches).length){
      groups.push({'name': 'New AI-Verified Matches', 'searches': savedSearches.filter(s => s.count_unread_ai_matches)})
    }
    if (savedSearches.filter(s => s.count_unread && !s.count_unread_ai_matches).length){
      groups.push({'name': 'New Keyword Matches', 'searches': savedSearches.filter(s => s.count_unread && !s.count_unread_ai_matches)})
    }
    groups.push({'name': 'No New Matches', 'searches': savedSearches.filter(s => !s.count_unread)})
  } else if (grouping === 'Type'){
    if (savedSearches.filter(s => s.match_criteria.include || s.match_criteria.exclude).length){
      groups.push({'name': 'AI-Verified', 'searches': savedSearches.filter(s => s.match_criteria.include || s.match_criteria.exclude)})
    }
    if (savedSearches.filter(s => s.notify_instantly).length){
      groups.push({'name': 'Instant Alerts', 'searches': savedSearches.filter(s => s.notify_instantly)})
    }
    groups.push({'name': 'Regular Matches', 'searches': savedSearches.filter(s => !s.match_criteria.include && !s.match_criteria.exclude && !s.notify_instantly)})
  } else if (grouping === 'Audience'){
    // group by collection
    savedSearches.forEach((s) => {
      const groupIndex = groups.map(g => g.name).indexOf(s.params.collection);
      if (groupIndex !== -1){
        groups[groupIndex]['searches'].push(s)
      } else {
        groups.push({'name': s.params.collection, 'searches': [s]})
      }
    });
    // now sort the groups (no collection at the end)
    // TODO: I don't really understand why it's not sorting by length properly
    groups = groups.sort((a,b) => (!a.name || (a.searches.length > b.searches.length)) ? 1 : (!b.name || (a.searches.length > b.searches.length) ? -1 : 0));
  } else if (grouping === 'Keyword'){
    // group by keyword (with no keyword at the end)
    savedSearches.filter(s => s.params.keyword).sort((a,b) => (a.params.keyword && a.params.keyword.toLowerCase() > b.params.keyword && b.params.keyword.toLowerCase()) ? 1 : ((b.params.keyword && b.params.keyword.toLowerCase() > a.params.keyword && a.params.keyword.toLowerCase()) ? -1 : 0)).forEach((s) => {
      const groupIndex = groups.map(g => g.name).indexOf(s.params.keyword);
      if (groupIndex !== -1){
        groups[groupIndex]['searches'].push(s)
      } else {
        groups.push({'name': s.params.keyword, 'searches': [s]})
      }
    })
    groups.push({'name': '', 'searches': savedSearches.filter(s => !s.params.keyword)})
  } else if (grouping === 'Label'){
    // group by label (with no label at the end)
    savedSearches.filter(s => s.label).sort((a,b) => (a.label && a.label.toLowerCase() > b.label && b.label.toLowerCase()) ? 1 : ((b.label && b.label.toLowerCase() > a.label && a.label.toLowerCase()) ? -1 : 0)).forEach((s) => {
      const labelToUse = s.label || ''; // makes it such that we convert nulls to ''
      const groupIndex = groups.map(g => g.name).indexOf(labelToUse);
      if (groupIndex !== -1){
        groups[groupIndex]['searches'].push(s)
      } else {
        groups.push({'name': labelToUse, 'searches': [s]})
      }
    })
    groups.push({'name': '', 'searches': savedSearches.filter(s => !s.label)})
  } else if (grouping === 'Channel'){
    // group by channel (with no label at the end)
    savedSearches.filter(s => s.notification_channel).sort((a,b) => (a.notification_channel.nickname > b.notification_channel.nickname) ? 1 : ((b.notification_channel.nickname > a.notification_channel.nickname) ? -1 : 0)).forEach((s) => {
      const label = s.notification_channel.nickname || s.notification_channel.channel_type
      const groupIndex = groups.map(g => g.name).indexOf(label);
      if (groupIndex !== -1){
        groups[groupIndex]['searches'].push(s)
      } else {
        groups.push({'name': label, 'searches': [s]})
      }
    })
    groups.push({'name': 'Default Notification Channel', 'searches': savedSearches.filter(s => !s.notification_channel)})
  }

  // calculate velocity so that we can compare visually across rows
  var maxVelocity = 1;
  savedSearches.forEach((s) => {
    if (s.stats && s.stats.track_days > 1 && s.stats.recent_velocity && s.stats.recent_velocity > maxVelocity){ maxVelocity = s.stats.recent_velocity }
  });
  var maxPerformanceScore = 0.0000000001; // just something tiny but > 0
  savedSearches.forEach((s) => {
    if (s.stats && s.stats.track_days > 1 && s.stats.performance && s.stats.performance > maxPerformanceScore){ maxPerformanceScore = s.stats.performance }
  });
  var maxEfficiencyScore = 0.0000000001; // just something tiny but > 0
  savedSearches.forEach((s) => {
    if (s.stats && s.stats.track_days > 1 && s.stats.efficiency && s.stats.efficiency > maxEfficiencyScore){ maxEfficiencyScore = s.stats.efficiency }
  });

  const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");

  // base url where this list lives, so that we can open up drawer appropriately
  const baseUrl = `${urlRoot || '/conversations/tracked/'}`;

  return (
    <ErrorBoundary>
      <div className="flex items-center mb-2">
        <h2 className="text-lg font-medium">Tracked Searches</h2>

        <div className="ml-2 text-gray-500 truncate flex items-center">
          <span className="cursor-pointer mr-2">{savedSearches.length}</span>

          {loading ? (
            <svg className="animate-spin h-4 w-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : ''}

          {!loading ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 cursor-pointer opacity-75 hover:opacity-100 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              // onClick={() => refetchUnread(5)} # TODO: paginate the sync
              onClick={refetch}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
          ) : ''}
        </div>


        <div className="ml-auto flex-wrap flex">      
          <ReactTooltip id={`keyword-list-helper`} className="text-xs p-2" place="top" effect="solid" backgroundColor="white" textColor="black" />

          {currentUser && currentUser.features.tracked_keyword_stats && savedSearches.length >= 2 ? (
            <div className="flex items-center">
              <div className="text-xs opacity-50">Stats</div>
              <div className="ml-1 flex items-center"
                data-for={`keyword-list-helper`} data-tip="Show stats on frequency & accuracy of each tracked keyword."
              >
                <Toggle
                  defaultChecked={includeStats}
                  className="toggle-darkmode"
                  icons={{
                    checked: <CheckIcon className="h-5 w-5"/>,
                    unchecked: null
                  }}
                  onChange={(e) => {
                    setIncludeStats(e.target.checked ? true : false)
                  }}/>
              </div>
            </div>
          ) : ''}

          <div className="ml-4 items-center hidden sm:flex">
            <div className="text-xs opacity-50 mr-2">Notifications</div>
            {(currentUser.subscription || currentUser.trial.active) && currentUser.options.digests_enabled ? (
              <div className="text-xs text-gray-300">
                <Link className="underline hover:text-gray-200" to="/account/notifications/">{currentUser.options.digests_cadence} to {currentUser.options.digests_channel || 'your email'}</Link>
              </div>
            ) : (
              <div className="text-xs text-gray-300">
                <Link className="underline hover:text-gray-200" to="/account/notifications/">Disabled</Link>
              </div>
            )}
          </div>

          {!hideGroup && savedSearches.length >= 5 ? (
            <div className="ml-4 items-center hidden sm:flex">
              <div className="text-xs opacity-50 mr-2">Group by</div>
              <SelectPopover
                options={groupings}
                currentOption={grouping}
                setCurrentOption={setGrouping}
                labelField={undefined}
              />
            </div>
          ) : ''}
        </div>
      </div>
        
      {savedSearches && savedSearches.length ? (
        <div className="">

          {savedSearches.filter(s => s.match_criteria.include || s.match_criteria.exclude).length == 0 ? (
            <div className="text-xs sm:text-sm text-gray-400 flex bg-gray-800 text-white p-2 rounded-md items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 mr-2 text-green-500 flex-shrink-0">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
              </svg>

              New: Add AI verification for more accurate matches. Open a tracked search and press the gear icon to get started.
            </div>
          ) : ''}

          {groups.map(group => (
            <React.Fragment key={group.name || 'No Group Name'}>
              {grouping !== 'None' ? (
                <h3 className="mt-4 text-md font-medium mb-2 opacity-50 flex items-center">
                  {grouping === 'Audience' ? (
                    <React.Fragment>
                      {group.name ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-0 h-4 w-4 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      ) : ''}
                      <span>{group.name ? getCollectionNameFromHash(group.name) : 'No Audience'}</span>
                    </React.Fragment>
                  ) : grouping === 'Keyword' ? (
                    <span className="ml-0">{group.name ? `"${group.name}"` : `No Keyword`}</span>
                  ) : grouping === 'Label' ? (
                    <React.Fragment>
                      {group.name ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-0 h-4 w-4 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                        </svg>
                      ) : ''}
                      <span className="ml-0">{group.name ? `${group.name}` : `No Label`}</span>
                    </React.Fragment>
                  ) : grouping === 'Matches' ? (
                    <span className="ml-0">{group.name ? `${group.name}` : `No Matches`}</span>
                  ) : grouping === 'Channel' ? (
                    <span className="ml-0">{group.name ? `${group.name}` : `No Channel`}</span>
                  ) : grouping === 'Type' ? (
                    <span className="ml-0">{group.name ? `${group.name}` : `No Type`}</span>
                  ) : ''}

                  
                </h3>
              ) : ''}
              
              <div className={`gap-y-2 ${showGrid ? `grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8` : ``}`}>
                {group.searches.map(search => (
                  <TrackedKeywordRow key={search.hash} search={search} getCollectionNameFromHash={getCollectionNameFromHash}
                    fetchingUnread={updatingHashes && updatingHashes.includes(search.hash)}
                    unreadCount={search.count_unread} unreadAiMatchesCount={search.count_unread_ai_matches}
                    maxVelocity={maxVelocity} maxPerformanceScore={maxPerformanceScore} maxEfficiencyScore={maxEfficiencyScore}
                    includeStats={includeStats} baseUrl={baseUrl}
                    hideAudienceLabel={hideAudienceLabel}
                  />
                ))}

                {/*{group.searches.length === 0 ? (
                  <div className="opacity-25">None yet...</div>
                ) : ''}*/}
              </div>
            </React.Fragment>
          ))}

          {true || grouping === 'None' ? (
            <Link to="/conversations/info/" className={`py-4 text-sm rounded-md shadow-lg border-2 border-dashed text-gray-400 border-gray-800 hover:border-gray-700 hover:text-gray-200 text-white flex flex-col text-center justify-center`}>
              New tracked search
            </Link>
          ) : ''}
        </div>
      ) : (
        <Link className="opacity-75 hover:opacity-100 cursor-pointer border-2 border-dashed border-gray-700 p-4 h-32 w-full rounded-lg flex flex-col items-center justify-center"
          // onClick={() => document.getElementById('keyword-search').focus()}
          to="/conversations/info/"
        >
          <h3>No Tracked Keywords Yet</h3>
          <div className="text-sm opacity-75 mt-2 text-center">
            Make a search and track it to get notified of new keyword matches
          </div>
        </Link>
      )}
    </ErrorBoundary>
  );
}

export default TrackedKeywordsList;
