import React, { useState, useEffect, Fragment } from "react"
import { connect } from 'react-redux';

import {
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";
import {
  PlusIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon,
} from '@heroicons/react/outline'


import PropsRoute from "../../components/common/propsroute";
import NavBar from "../../components/common/navbar";
import Layout from "../../components/layout";
import LoaderFancy from "../../components/common/loaderfancy";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";

// import AudienceCard from "../../components/audiences/audiencecard"
// import NameAudience from "../../components/audiences/nameaudience"
// import AudiencesNewModal from "../../components/audiences/audiencesNewModal"
import Top40InfoModal from "../../components/top/topInfoModal"
import Top40List from "../../components/top/list"
import FeaturePaywallWrapper from "../../components/account/featurePaywallWrapper"

import { audiencesOperations } from "../../redux/audiences"

// import { audiencesOperations } from "../../redux/audiences"
// import { uiOperations } from "../../redux/ui"
// import { collectionsService } from '../../services/collections';



// top subreddits page
const TrendingAudiences = ({
   history, location, currentUser,
}) => {
  //state
  const lists = [
    {'key': 'largest', 'title': 'Largest',
      'metrics': [
        {'key': 'current_members', 'title': 'Members'},
        // {'key': 'current_comments', 'title': 'Comments'},
        // {'key': 'current_submissions', 'title': 'Submissions'},
      ],
      'useTimeframes': false,
    },
    {'key': 'activity', 'title': 'Active', 
      'metrics': [
        {'key': 'velocity', 'title': 'Submissions per day'},
      ],
      'useTimeframes': false,
    },
    // {'key': 'engaged', 'title': 'Engaged',
    //   'metrics': [
    //     {'key': 'per_user_comments', 'title': 'Comments per user'},
    //     {'key': 'per_user_submissions', 'title': 'Submissions per user'},
    //   ],
    //   'useTimeframes': false,
    // },
    {'key': 'growing', 'title': 'Growing',
      'metrics': [
        {'key': 'growth_members', 'title': 'Member Growth', 'suffix': '%', 'displayFunction': (value) => {
          if (value > 100) {
            return `x${(value / 100).toFixed(2)}`;
          } else if (value > 10) {
            return `+${value.toFixed(0)}%`;
          } else if (value > 1) {
            return `+${value.toFixed(1)}%`;
          } else if (value > 0) {
            return `+${value.toFixed(2)}%`;
          }
          return '--';
        }},
        // {'key': 'growth_comments', 'title': 'Comment Growth', 'suffix': '%'},
        // {'key': 'growth_submissions', 'title': 'Submission Growth', 'suffix': '%'},
      ],
      'useTimeframes': true,
    },
    
    // {'key': 'age', 'title': 'Oldest',
    //   'metrics': [
    //     {'key': 'total_days', 'title': 'Days'},
    //     // {'key': 'current_comments', 'title': 'Comments'},
    //     // {'key': 'current_submissions', 'title': 'Submissions'},
    //   ],
    //   'useTimeframes': false,
    // },
  ]

  const timeframes = [
    {'key': 'daily', 'title': 'Daily'},
    {'key': 'weekly', 'title': 'Weekly'},
    {'key': 'monthly', 'title': 'Monthly'},
    {'key': 'yearly', 'title': 'Yearly'},
  ]

  const sizes = [
    {'key': 'all', 'title': 'All Sizes', 'subtitle': 'All Sizes'},
    {'key': 'size-massive', 'title': 'Massive', 'subtitle': '1M+ Members'},
    {'key': 'size-huge', 'title': 'Huge', 'subtitle': '100k - 1M'},
    {'key': 'size-large', 'title': 'Large', 'subtitle': '10k - 100k'},
    {'key': 'size-medium', 'title': 'Medium Size', 'subtitle': '1k - 10k'},
    {'key': 'size-small', 'title': 'Small', 'subtitle': '100 - 1k'},
    {'key': 'size-tiny', 'title': 'Tiny', 'subtitle': '<100 Members'},
  ]

  const baseUrl = `/audiences/trending/`;
  const tabs = lists.map(l => {
    return {
      name: l.title,
      link: `${baseUrl}${l.key}/`,
    }
  });

  // prob dont need this anymore
  // const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  // const isEffectiveProSubscriber = isProSubscriber || currentUser.trial.plan === 'pro';

  return (
    <FeaturePaywallWrapper featureKey={"trending-subreddits"} className={'mx-auto'} currentUser={currentUser}>
      <div>
        <Helmet><title>Audiences | Trending</title></Helmet>
        
        <ErrorBoundary>
          <div>

            <Switch>
              <PropsRoute path={`/audiences/trending/:listKey/`} component={Top40List}
                lists={lists} timeframes={timeframes} sizes={sizes} history={history}
                // isEffectiveProSubscriber={true} 
              />
              <Redirect exact from={`/audiences/trending/`} to={tabs[0]['link']}/>
            </Switch>

          </div>
        </ErrorBoundary>
      </div>
    </FeaturePaywallWrapper>
  );
}


export default TrendingAudiences;
