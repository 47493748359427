import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { RadioGroup } from '@headlessui/react'
import { ModalRoute, ModalContainer } from 'react-router-modal';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';


import Modal from "../../components/common/modal";
import Loader from "../../components/common/loader";

import NotificationsSlackModal from "../../components/account/notificationsSlack";
import NotificationsDiscordModal from "../../components/account/notificationsDiscord";
import NotificationsEmailModal from "../../components/account/notificationsEmail";
import NotificationsWebhookModal from "../../components/account/notificationsWebhook";

import { digestsService } from '../../services/digests';
import { authService } from "../../services/auth";
import { profileService } from '../../services/profile';
import { userOperations } from "../../redux/user";



const Notifications = ({history, location, match, currentUser, setCurrentUser}) => {  
  const [loadingDigestSettings, setLoadingDigestSettings] = useState(true); 
  const [digestSettings, setDigestSettings] = useState(null); 
  const [loadingNotificationChannels, setLoadingNotificationChannels] = useState(true); 
  const [notificationChannels, setNotificationChannels] = useState([]); 
  const [updatingSettings, setUpdatingSettings] = useState(false); 
  const [updatingProfile, setUpdatingProfile] = useState(false); 
  const [userProfile, setUserProfile] = useState(currentUser.profile);

  // // effects
  useEffect(() => {
    fetchDigestSettings();
    fetchNotificationChannels();
  }, []);

  // actions
  const fetchDigestSettings = () => {
    setLoadingDigestSettings(true);
    digestsService.getDigestSettings((response) => {
      setDigestSettings(response.data)
      setLoadingDigestSettings(false);
    }, (error) => {
      // totally OK to not exist yet
      setDigestSettings(null)
      setLoadingDigestSettings(false)
    });
  }

  const createDigestSettings = () => {
    setLoadingDigestSettings(true);
    digestsService.createDigestSettings((response) => {
      setDigestSettings(response.data)
      setLoadingDigestSettings(false);
    }, (error, response) => {
      alert("Failed to create digest settings")
      setLoadingDigestSettings(false)
    });
  }

  // update settings. params is like {'enabled': false} or {'frequency': 'DA'}
  const updateSettings = (params, callback) => {
    setUpdatingSettings(true);
    digestsService.updateDigestSettings(params, (response) => {
      setDigestSettings(response.data)
      setUpdatingSettings(false);

      // refetch user config
      authService.fetchUser((response) => {
        setUserProfile(response.profile)
        setCurrentUser(response)
      });

      if (callback){
        callback()
      }
    }, (error) => {
      alert("Failed to update")
      setUpdatingSettings(false)
    });
  }

  const fetchNotificationChannels = () => {
    setLoadingNotificationChannels(true);
    digestsService.getNotificationChannels((response) => {
      setNotificationChannels(response.data);
      setLoadingNotificationChannels(false);
    }, (error) => {
      setLoadingNotificationChannels(false)
    });
  }

  const createNotificationChannel = (data, callback, onError) => {
    setLoadingNotificationChannels(true);
    digestsService.createNotificationChannel(data, (response) => {
      setNotificationChannels([...notificationChannels, response.data])
      setLoadingNotificationChannels(false);
      if (callback){
        callback()
      }
    }, (error, response) => {
      alert(error.response.status === 500 ? "Failed to create notification channel" : response)
      setLoadingNotificationChannels(false)
      if (onError){
        onError()
      }
    });
  }

  const updateNotificationChannel = (id, data, callback, onError) => {
    setLoadingNotificationChannels(true);
    digestsService.updateNotificationChannel(id, data, (response) => {
      fetchNotificationChannels()
    }, (error, response) => {
      alert(error.response.status === 500 ? "Failed to update notification channel" : response)
      setLoadingNotificationChannels(false)
      if (onError){
        onError()
      }
    });
  }

  const deleteNotificationChannel = (id, callback) => {
    // setLoadingNotificationChannels(true);
    digestsService.deleteNotificationChannel(id, (response) => {
      setNotificationChannels(notificationChannels.filter(n => n.id !== id))
      // setLoadingNotificationChannels(false);

      if (callback){
        callback()
      }
    }, (error, response) => {
      alert("Failed to delete notification channel")
      // setLoadingNotificationChannels(false)
    });
  }

  const updateProfile = (params, callback) => {
    // update user profile
    setUpdatingProfile(true);
    profileService.updateProfile(currentUser.profile.id, params, (response) => {
      setUpdatingProfile(false);

      // refetch user config
      authService.fetchUser((response) => {
        setUserProfile(response.profile)
        setCurrentUser(response)
      });

      if (callback){
        callback()
      }

    }, (error) => {
      console.error(error)
      setUpdatingProfile(false)
    });
  }

  const clickNotificationChannel = (key) => {
    history.push(`/account/notifications/${key}/`); // configure modal for that channel
    
    // // clicked on configure. if email, set to it. Otherwise open modal for configuration
    // if (key === 'EM'){
    //   if (!digestSettings.notification_channel || (digestSettings.notification_channel.channel_type !== 'EM')){
    //     // update settings if it's not already set to email notifications
    //     updateSettings({'notification_channel': {'channel_type': 'EM'}})
    //   }
    // } else {
    //   history.push(`/account/notifications/${key}/`); // configure modal for that channel
    // }
  }

  const enabledOptions = [
    {'value': true, 'label': 'Yes'},
    {'value': false, 'label': 'No'},
  ]
  const frequencyOptions = [
    {'value': 'DA', 'label': 'Daily'},
    {'value': 'WE', 'label': 'Weekly'},
    {'value': 'MO', 'label': 'Monthly'},
  ]
  const channelOptions = [ // TODO: add icons and also put in constants file
    {'value': 'EM', 'label': 'Email'},
    {'value': 'SE', 'label': 'Slack'},
    {'value': 'DI', 'label': 'Discord'},
    {'value': 'WE', 'label': 'Webhook'},
    // {'value': 'ZA', 'label': 'Zapier'},
  ]
  // defaults to email if not set
  var currentChannel = digestSettings && digestSettings.notification_channel;
  // if (!currentChannel){
  //   currentChannel = channelOptions[0]; // default to email if not set
  // }

  // const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  // const isStarterSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "starter");
  const isSubscriber = currentUser && currentUser.subscription;

  return (
    <div className="my-8 pb-8">
      <Helmet><title>Account | Notifications</title></Helmet>
      
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Tracked Keyword Digests</h3>
              <p className="mt-2 text-sm text-gray-400">
                Get notified of new matches for your tracked keywords.<br/>
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow rounded-md overflow-hidden">
              <div className="px-4 py-5 bg-gray-800 space-y-6 sm:p-6">
                {loadingDigestSettings ? (
                  <div><Loader /></div>
                ) : !digestSettings ? (
                  <div>
                    <p className="text-sm text-gray-400">
                      You don't currently have tracked keyword digests enabled on your account.
                    </p>
                    <p className="mt-2 text-sm text-gray-400">
                      Digests will let you know when you have new hits for your tracked keywords.
                    </p>
                    <div className="mt-4">
                      <button
                        onClick={createDigestSettings}
                        className="inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                      >
                        Enable Digests
                      </button>
                    </div>
                  </div>
                ) : (
                  <div disabled={updatingSettings}>

                    {!isSubscriber && (currentUser.trial && !currentUser.trial.active) ? (
                      <Link to="/account/subscription/select/" className="mb-2 text-sm text-yellow-500 flex items-center hover:underline">
                        <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Unavailable" className="h-4 w-4 mr-1 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div>Sending tracked keyword digests requires a paid plan</div>
                      </Link>
                    ) : ''}

                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="digest-enabled" className="flex items-center text-sm font-medium text-gray-500">
                          <div>Enabled</div>
                        </label>
                        <div className="mt-1 flex">
                          {enabledOptions.map(o => (
                            <div key={o.label} className="relative py-2 flex items-center ml-2 mr-4">
                              <div className="flex items-center h-5">
                                <input id={`enabled-${o.label}`} name="enabled" type="radio"
                                  className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                                  checked={(digestSettings && digestSettings.enabled) === o.value || (!digestSettings && o.value === false)}
                                  onChange={(e) => {
                                    updateSettings({
                                      'enabled': o.value
                                    })
                                  }}
                                />
                              </div>
                              <label htmlFor={`enabled-${o.label}`} className="ml-2 cursor-pointer">
                                <span className="block text-sm font-medium flex items-center">{o.label}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 col-span-3 sm:col-span-2" disabled={!digestSettings.enabled}>
                      <label htmlFor="digest-channel" className="flex items-center text-sm font-medium text-gray-500">
                        <div>Frequency</div>
                      </label>
                      <div className="mt-1 flex">
                        {frequencyOptions.map(o => (
                          <div key={o.label} className="relative py-2 flex items-center ml-2 mr-4">
                            <div className="flex items-center h-5">
                              <input id={`frequency-${o.label}`} name="frequency" type="radio"
                                className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                                checked={digestSettings && digestSettings.frequency === o.value}
                                onChange={(e) => updateSettings({
                                  'frequency': o.value
                                })}
                              />
                            </div>
                            <label htmlFor={`frequency-${o.label}`} className="ml-2 cursor-pointer">
                              <span className="block text-sm font-medium flex items-center">{o.label}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>


                    <div className="mt-2 col-span-3 sm:col-span-2" disabled={!digestSettings.enabled}>
                      <ReactTooltip id={`additional-channels-tooltip`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                
                      <label htmlFor="additional-notification-channel" className="flex items-center text-sm font-medium text-gray-500">
                        <div className="flex items-center">Notification Channels

                          <span className="cursor-pointer ml-2" data-for='additional-channels-tooltip' data-tip="Only necessary if you want different tracked keywords to go to different emails/channels/webhooks">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                        </div>
                      </label>
                      <div className="mt-1 space-y-2">
                        {!currentChannel ? (
                          <div className="text-gray-200">
                            <div>No default notification channel set.</div>
                            <div>Tracked keyword digests will go to your account email.</div>
                          </div>
                        ) : ''}

                        {notificationChannels.map((n) => (
                          <div key={n.id} className="flex items-center border border-gray-600 p-2 rounded-md">
                            {/*<div className="w-8">
                              
                            </div>*/}

                            <div>
                              <div className="font-bold flex items-center">
                                <div className="singleLineClip">{n.nickname || channelOptions.filter(o => o.value === n.channel_type)[0].label}</div>
                              </div>
                              <div className="text-sm text-gray-500 singleLineClip">
                                {n.channel_type === "EM" ? (
                                  <div>{n.params.email || `Account Email (${currentUser.identity.email})`}</div>
                                ) : n.channel_type === "SE" ? (
                                  <div>{n.params && n.params.email}</div>
                                ) : n.channel_type === "DI" ? (
                                  <div>{n.params && n.params.webhook}</div>
                                ) : n.channel_type === "WE" ? (
                                  <div>{n.params && n.params.webhook}</div>
                                ) : 'Invalid Type'}
                              </div>
                            </div>

                            <div className="ml-auto flex-shrink-0">
                              {n.is_default ? (
                                <button className={`flex items-center cursor-pointer text-sm font-semibold py-2 px-4 rounded-md hover:opacity-90 border text-white bg-green-500 border-transparent`}
                                  disabled={true}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                  </svg>
                                  Default
                                </button>
                              ) : (
                                <button className={`flex items-center cursor-pointer text-sm font-semibold py-2 px-4 rounded-md hover:opacity-90 border text-white bg-cyan-500 border-transparent`}
                                  onClick={() => {
                                    updateSettings({'notification_channel_id': n.id}, () => {
                                      fetchNotificationChannels()
                                    })
                                  }}
                                >
                                  Set Default
                                </button>
                              )}
                            </div>

                            <div className="ml-2 flex space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                className="h-6 w-6 text-gray-500 hover:text-gray-400 cursor-pointer"
                                onClick={() => {
                                  const nickname = prompt('Set a nickname for this notification channel', n.nickname);
                                  if (nickname){
                                    updateNotificationChannel(n.id, {'nickname': nickname})
                                  }
                                }}
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                              </svg>

                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}
                                onClick={() => {
                                  if (window.confirm("Are you sure you want to delete this notification channel?")){
                                    deleteNotificationChannel(n.id, () => {
                                      if (currentChannel && n.id === currentChannel.id){
                                        fetchDigestSettings()
                                      }
                                    })
                                  }
                                }}
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            </div>
                          </div>
                        ))}

                        <div className="mt-1 flex space-x-2">
                          {channelOptions.map(channel => (
                            <button key={channel.value}
                              onClick={() => clickNotificationChannel(channel.value)}
                              className={`flex items-center cursor-pointer text-sm font-semibold py-1 px-2 rounded-md hover:opacity-90 border text-white bg-cyan-600 border-transparent`}
                            >
                              <div>+ {channel.label}</div>
                            </button>
                          ))}
                        </div>
                      </div>

                      
                    </div>

                    {/*<div className="mt-2 col-span-3 sm:col-span-2" disabled={!digestSettings.enabled}>
                      <label htmlFor="digest-channel" className="flex items-center text-sm font-medium text-gray-500">
                        <div>Add Notification Channel</div>
                      </label>

                      <div className="mt-1 flex space-x-2">
                        {channelOptions.map(channel => (
                          <div key={channel.value}
                            onClick={() => clickNotificationChannel(channel.value)}
                            className={`p-4 relative border cursor-pointer rounded-md border-gray-600 hover:border-gray-500`}
                          >
                            <div>{channel.label}</div>
                          </div>
                        ))}
                      </div>
                    </div>*/}
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>

        <div className="md:grid md:grid-cols-3 md:gap-6 mt-8">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Email Updates from Us</h3>
              <p className="mt-2 text-sm text-gray-400">
                Allows GummySearch to send you emails regarding important updates on current + future product & pricing.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow rounded-md overflow-hidden">
              <div className="px-4 py-5 bg-gray-800 space-y-6 sm:p-6">
                <div disabled={updatingSettings}>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="emails-enabled" className="flex items-center text-sm font-medium text-gray-500">
                        <div>Emails Enabled</div>
                      </label>
                      <div className="mt-1 flex" disabled={updatingProfile}>
                        {enabledOptions.map(o => (
                          <div key={o.label} className="relative py-2 flex items-center ml-2 mr-4">
                            <div className="flex items-center h-5">
                              <input id={`emails-${o.label}`} name="allow_email_sending" type="radio"
                                className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                                checked={userProfile.allow_email_sending === o.value}
                                onChange={(e) => {
                                  updateProfile({
                                    'allow_email_sending': o.value
                                  })
                                }}
                              />
                            </div>
                            <label htmlFor={`emails-${o.label}`} className="ml-2 cursor-pointer">
                              <span className="block text-sm font-medium flex items-center">{o.label}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {digestSettings ? (
        <React.Fragment>
          <ModalRoute
            path={`/account/notifications/SE/`}
            parentPath={`/account/notifications/`}
            component={NotificationsSlackModal}
            props={{
              digestSettings,
              updateSettings,
              currentUser,
              createNotificationChannel,
            }}
          />
          <ModalRoute
            path={`/account/notifications/DI/`}
            parentPath={`/account/notifications/`}
            component={NotificationsDiscordModal}
            props={{
              digestSettings,
              updateSettings,
              currentUser,
              createNotificationChannel,
            }}
          />
          <ModalRoute
            path={`/account/notifications/EM/`}
            parentPath={`/account/notifications/`}
            component={NotificationsEmailModal}
            props={{
              digestSettings,
              updateSettings,
              currentUser,
              createNotificationChannel,
            }}
          />
          <ModalRoute
            path={`/account/notifications/WE/`}
            parentPath={`/account/notifications/`}
            component={NotificationsWebhookModal}
            props={{
              digestSettings,
              updateSettings,
              currentUser,
              createNotificationChannel,
            }}
          />
        </React.Fragment>
      ) : ''}
      <ModalContainer />

    </div>
  )
}


// this is shared state, so wrap in a dispatch
const mapDispatchToProps = (dispatch) => {
  const setCurrentUser = (user) => {
    dispatch(userOperations.setCurrentUser(user))
  };
  return {
    setCurrentUser,
  };
};

export default connect(null, mapDispatchToProps)(Notifications);

