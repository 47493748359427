import React from "react"
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";

import PropsRoute from '../../components/common/propsroute.jsx';
import NavBar from "../../components/common/navbar";
import ErrorBoundary from "../../components/common/errorboundary";
import Layout from "../../components/layout";

import Subscription from "./subscription";
import Notifications from "./notifications";
import Results from "./results";
// import AccountDetails from "./details"


const Account = ({history, location, match, currentUser}) => {

  const tabs = [
    // {'name': 'Details', 'link': '/account/details/'},
    {'name': 'Subscription', 'link': '/account/subscription/'},
    {'name': 'Notifications', 'link': '/account/notifications/'},
    // {'name': 'Results', 'link': '/account/results/'},
  ]

  return (
    <Layout currentUser={currentUser} >
      <Helmet><title>Account</title></Helmet>

      <ErrorBoundary>
        <div className="flex items-center sm:-mt-3 pb-2">
          <div>
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
                Your Account
                {/* <span className="text-sm opacity-50 font-semibold ml-2">{currentUser.identity.email}</span>*/}
              </h2>
            </div>
          </div>
        </div>

        <div className="border-b border-solid border-gray-800">
          <NavBar tabs={tabs}/>
        </div>

        <Switch>
          <PropsRoute path="/account/subscription/" component={Subscription} />
          <PropsRoute path="/account/results/" component={Results} currentUser={currentUser} />
          <PropsRoute path="/account/notifications/" component={Notifications} currentUser={currentUser} />
          {/*<PropsRoute path="/account/" component={AccountDetails} currentUser={currentUser} />*/}
          <Redirect exact path="/account/" to="/account/subscription/" />
          <Redirect exact path="/account/details/" to="/account/subscription/" /> {/* old redirect */}
        </Switch>
      </ErrorBoundary>
    </Layout>
  );
}

export default Account;
