import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";

import {
  InformationCircleIcon
} from '@heroicons/react/outline'

import { savedSearchOperations } from "../../redux/saved"
import { audiencesOperations } from "../../redux/audiences"
import { profileService } from '../../services/profile';
import { searchesService } from '../../services/searches';
import Layout from "../../components/layout";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";
import LoaderFancy from '../../components/common/loaderfancy.jsx';
import SelectPopover from "../../components/common/selectPopover";

import ReportsList from '../../components/reports/reportsList.jsx';
import ReportsInfoModal from '../../components/reports/reportsInfo.jsx';
import AiMinutePopover from "../../components/language/aiminutepopover";
import AiMinuteUsage from "../../components/language/aiminuteusage";
import SharedReportViews from '../../components/reports/sharedReportViews';
// import SavedConversationsList from "../../components/conversations/savedconversationslist";
import SavedConversationsDrawer from "../../components/conversations/savedconversationsdrawer";


const Reports = ({
  currentUser, history,
  savedLists, fetchingSavedLists,
  fetchingTrackedKeywords,
  fetchAllSavedSearches,
}) => {
  // state
  const [error, setError] = useState(null);
  const [loadingLimits, setLoadingLimits] = useState(false); 
  const [limits, setLimits] = useState(null); 
  const [reportViews, setReportViews] = useState(null);
  const [loadingReportViews, setLoadingReportViews] = useState(false);
  const sharedReportTypes = ['Day', 'Month'];
  const [sharedReportType, setSharedReportType] = useState(sharedReportTypes[0]);


  // effects
  useEffect(() => {
    // get limits to show AI minutes used
    getSubscriptionLimits();

    // get the report views
    getAnalysisReportViews();
  }, []);

  const getSubscriptionLimits = () => {
    if (loadingLimits) return;

    // fetch the limits & current usage of the account
    setLoadingLimits(true)
    profileService.getLimits((response) => {
      setLimits(response.data)
      setLoadingLimits(false)
    })
  }

  const getAnalysisReportViews = () => {
    if (loadingReportViews) return;

    setLoadingReportViews(true)
    searchesService.fetchAnalysisReportViews((response) => {
      setReportViews(response.data);
      setLoadingReportViews(false);

      if (response.data.days.length >= 60){
        setSharedReportType('Month');
      }
    })
  }


  return (
    <Layout currentUser={currentUser} >
      <Helmet><title>Reports</title></Helmet>
      
      <ErrorBoundary>
        <div className="">
          <div className="flex items-center flex-wrap sm:-mt-3 mb-6 pb-5 border-b border-solid border-gray-800">
            <div>
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
                  AI Reports
                </h2>
              </div>
            </div>
            <div className="ml-auto flex">

              <Link to={`/reports/info/`}
                type="button"
                className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
              >
                <InformationCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Info
              </Link>
            </div>
          </div>

          {false ? (
            <div className={true ? 'h-64 relative' : 'h-64'}>
              <LoaderFancy />
            </div>
          ) : (
            <div className="w-full my-4 grid lg:grid-cols-3 lg:space-x-8">
              <div className="md:col-span-2">
                <ReportsList currentUser={currentUser} history={history} />
              </div>

              
              <div className="md:col-span-1 mt-4 lg:mt-0">
                {limits && limits.ai_minutes_used ? (
                  <div className="mb-8">
                    <div className="flex items-center mb-2">
                      <h2 className="text-lg font-medium block">AI Minutes Used</h2>

                      <div className="text-gray-500 flex items-center">
                        <AiMinutePopover limits={limits} hideBar={true} hideChart={true} positions={['top', 'right', 'bottom', 'left']}>
                          <InformationCircleIcon className="h-5 h-5 opacity-75 ml-2 mr-6 -bottom-1" aria-hidden="true"/>
                        </AiMinutePopover>
                      </div>

                      <div className="ml-auto text-gray-300 flex items-center">
                        <span>{limits.ai_minutes_used} / {limits.ai_minutes_allowed}</span>
                      </div>
                    </div>
                    <div className="pt-2">
                      <AiMinuteUsage limits={limits} />
                    </div>
                  </div>
                ) : ''}
                
                {reportViews && reportViews.total_count ? (
                  <div className="mt-8">
                    <div className="flex items-center mt-8">
                      <h2 className="text-lg font-medium block mr-2">Shared Report Views</h2>

                      <div className="ml-auto flex items-center">
                        <div className="ml-2 flex items-center">
                          <SelectPopover
                            options={sharedReportTypes}
                            currentOption={sharedReportType}
                            setCurrentOption={(s) => setSharedReportType(s)}
                            labelField={undefined}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-2">
                      {reportViews.total_count ? (
                        <SharedReportViews timeseries={reportViews} view={sharedReportType} />
                      ) : (
                        <div className="text-gray-500">No views yet</div>
                      )}
                    </div>
                  </div>
                ) : ''}

              </div>
            </div>
          )}

          <ErrorBoundary>
            <ModalRoute component={SavedConversationsDrawer}
              path={`/reports/saved/:savedSearchHash/`}
              parentPath={`/reports/`}
              props={{
                'currentUser': currentUser,
                'refetchAll': fetchAllSavedSearches, // TODO: updated just lists? But that wouldn't help if they just deleted
              }}
            />
            <ModalContainer />
          </ErrorBoundary>
        </div>
        
        {error ? (
          <ErrorBar error={error} setError={setError} />
        ) : ''}

        <ModalRoute
          path={`/reports/info/`}
          parentPath={`/reports/`}
          component={ReportsInfoModal}
          props={{
            'currentUser': currentUser,
          }}
        />
        <ModalContainer />
      </ErrorBoundary>
    </Layout>
  );
}


const mapStateToProps = state => {
  const {
    fetchingTrackedKeywords,
    trackedKeywords,
    fetchingSavedLists,
    savedLists,
    updating,
    updatingHashes,
  } = state.savedSearch;
  const {
    audiences,
    fetchingAudiences
  } = state.audiences;

  return {
    fetchingTrackedKeywords,
    trackedKeywords,
    fetchingSavedLists,
    savedLists,
    updating,
    updatingHashes,

    audiences,
    fetchingAudiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  const fetchAllSavedSearches = (callback, onError) => {
    dispatch(savedSearchOperations.fetchAllSavedSearches(callback, onError))
  };
  const updateSavedSearches = (params, callback, onError) => {
    dispatch(savedSearchOperations.updateSavedSearches(params, callback, onError))
  };
  const clearSavedSearchUnread = (hash) => {
    dispatch(savedSearchOperations.clearSavedSearchUnread(hash))
  };

  return {
    fetchAudiences,
    fetchAllSavedSearches,
    updateSavedSearches,
    clearSavedSearchUnread,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
