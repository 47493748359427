import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import Loader from "../../components/common/loader";
import LoaderFancy from "../../components/common/loaderfancy";
import ReportModal from '../../components/reports/reportModal';
import ErrorBoundary from "../common/errorboundary";
import SelectPopover from "../../components/common/selectPopover";

import { SUGGESTIONS } from '../../components/audiences/constants/suggestions';
import { searchesService } from '../../services/searches';
import { capitalizeFirst } from '../../utils/text';
import { useDebounce } from '../../utils/react';

const ReportsList = ({history, match, currentUser}) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const limitIncrement = 25;
  const [limit, setLimit] = useState(limitIncrement);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const typeOptions = ['All', 'Patterns', 'Ask'];
  const [typeOption, setTypeOption] = useState(typeOptions[0]);
  const sortOptions = ['Recent', 'Views'];
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const debouncedSearchTerm = useDebounce(searchTerm, 250);
  const isMobile = window.innerWidth < 768;
  const subredditsToShow = isMobile ? 1 : 3;
  
  useEffect(() => {
    fetchReports();
  }, [limit, typeOption, debouncedSearchTerm, sortOption]);

  const fetchReports = () => {
    setLoading(true);
    var params = { 'page_size': limit };
    if (typeOption && typeOption !== 'All'){
      params.reports_bulk = typeOption === 'Patterns' ? 'clusters' : 'ask';
    }
    if (debouncedSearchTerm){
      params.search = debouncedSearchTerm;
    }
    if (sortOption && sortOption !== 'Recent'){
      params.sort = sortOption
    }
    searchesService.recentAnalysisSearches(params, (response) => {
      setLoading(false);
      setReports(response.data.results);
      setTotalCount(response.data.count);
    }, (error) => {
      console.error(error)
      setLoading(false)
    });
  }

  const getReportTitle = (report) => {
    const type = getReportType(report);
    if (type === 'Patterns') {
      var title = ""
      if (report.params_results.suggestion) {
        const suggestion = SUGGESTIONS.find(s => s.slug === report.params_results.suggestion);
        title += suggestion.name
      } else if (report.params_results.topic) {
        title += `${capitalizeFirst(report.params_results.topic)}`
      } else if (report.params_results.keyword) {
        title += `${report.params_results.keyword}`
      } else if (report.params_results.sort) {
        title += report.params_results.sort
      }
      return title;
    }
    if (type === 'Ask') {
      return `"${report.params_analysis.ask_question}"`;
    }
    return `Unknown Title`;
  }

  const getReportType = (report) => {
    if (report.params_analysis && report.params_analysis.reports_bulk && report.params_analysis.reports_bulk.includes('clusters')) {
      return 'Patterns';
    }
    if (report.params_analysis && report.params_analysis.reports_bulk && report.params_analysis.reports_bulk.includes('ask')) {
      return 'Ask';
    }
    if (report.params_analysis && report.params_analysis.reports_bulk && report.params_analysis.reports_bulk.includes('summary')) {
      return 'Summary';
    }
    return 'Unknown';
  }

  const getReportResultsTitle = (report) => {
    const type = getReportType(report);
    if (type === 'Patterns') {
      return report.results.all.clusters.clusters.length + ' Pattern' + (report.results.all.clusters.clusters.length === 1 ? '' : 's');
    }
    if (type === 'Ask') {
      return report.results.all.ask.citations.length + ' Citation' + (report.results.all.ask.citations.length === 1 ? '' : 's');
    }
    if (type === 'Summary') {
      return 'Summary';
    }
    return '?';
  }

  return (
    <div className="">
      <div className="flex items-center mb-2">
        <h2 className="text-lg font-medium hidden sm:block mr-2">Reports</h2>

        <div className="text-gray-500 truncate">
          {loading ? (
            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : reports.length < totalCount ? (
            <span>{reports.length} / {totalCount}</span>
          ) : (
            <span>{reports.length}</span>
          )}
        </div>

        {reports.length || true ? (
          <div className="ml-auto flex">
            <div className="ml-4 flex items-center">
              <div className="text-xs opacity-50 hidden sm:block">Type</div>
              <div className="ml-2 flex items-center">
                <SelectPopover
                  options={typeOptions}
                  currentOption={typeOption}
                  setCurrentOption={(s) => setTypeOption(s)}
                  labelField={undefined}
                />
              </div>
            </div>

            <div className="ml-4 flex items-center">
              <div className="text-xs opacity-50 hidden sm:block">Sort</div>
              <div className="ml-2 flex items-center">
                <SelectPopover
                  options={sortOptions}
                  currentOption={sortOption}
                  setCurrentOption={(s) => setSortOption(s)}
                  labelField={undefined}
                />
              </div>
            </div>

            <div className="ml-4 flex items-center">
              <div className="text-xs opacity-50 hidden sm:block">Search</div>
              <input
                type="text"
                name="name"
                autoComplete="off"
                className={`ml-2 bg-gray-800 focus:bg-gray-700 border-transparent focus:border-gray-700 placeholder-gray-500 text-white block text-black w-full text-xs rounded-sm p-1 px-2 focus:ring-cyan-500 focus:border-cyan-500`}
                placeholder={`Search Reports`}
                style={{minWidth: '50px'}}
                value={searchTerm} onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
          </div>
        ) : ''}
        
      </div>
      <div className="flex">
        {loading && !reports.length ? (
          <div className="relative block h-96 w-full"><LoaderFancy /></div>
        ) : reports.length ? (
          <div className="w-full">
            {reports.map(report => (
              <Link key={report.hash} to={`/reports/report/${report.hash}/`} className="rounded-md shadow-lg bg-gray-800 hover:bg-gray-700 text-white flex p-3 mb-2">
                <ErrorBoundary>
                  <div className={`w-full sm:flex items-center text-base px-1`}>

                    <div className="pr-6 hidden sm:block">
                      <div className="whitespace-nowrap text-sm text-gray-500 flex items-center">
                        {getReportType(report) === 'Patterns' && (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                          </svg>
                        )}
                        {getReportType(report) === 'Ask' && (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                          </svg>
                        )}
                        {getReportType(report) === 'Summary' && (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                            <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                          </svg>
                        )}
                        <span className="text-gray-300">{getReportType(report)}</span>
                      </div>
                      <div className="text-gray-500 text-sm mt-1">{report.create_date}</div>
                    </div>
          
                    <div className="overflow-hidden">
                      <h3 className="text-gray-200 truncate overflow-hidden text-ellipsis font-semibold w-96 sm:max-w-none">{getReportTitle(report)}</h3>
                      {report.params_results.subreddits ? (
                        <div className="text-gray-500 truncate overflow-hidden text-ellipsis"
                          data-for='reports-tooltip' data-tip={report.params_results.subreddits && report.params_results.subreddits.split(',').length > 3 && report.params_results.subreddits.split(',').map(sub => `r/${sub}`).join(', ') || ''}
                          >
                          In {`${report.params_results.subreddits.split(',').slice(0, subredditsToShow).map(sub => `r/${sub}`).join(', ')}`}
                          {report.params_results.subreddits.split(',').length > subredditsToShow && 
                              ` + ${report.params_results.subreddits.split(',').length - subredditsToShow}`
                          }
                        </div>
                      ) : (
                        <div className="text-gray-500 text-sm mt-1">
                          In all of Reddit
                        </div>
                      )}
                    </div>
 
                    <div className={`ml-auto pl-6 whitespace-nowrap text-sm text-gray-300 flex items-center mt-2 sm:mt-0`}>
                      <div className="">
                        {report.shared_report_enabled && report.shared_report_hash ? (
                          <button onClick={(e) => {
                            e.preventDefault();
                            copy(`${currentUser.env.share_page_url}/report/${report.shared_report_hash}/`);
                            toast.success('Link copied to clipboard');
                          }}>
                            <div className="flex-1 sm:flex-none flex items-center text-center text-cyan-400 hover:text-cyan-300 hover:underline">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                              </svg>
                              Copy Link
                            </div>
                          </button>
                          ) : (
                          <Link to={`/reports/report/${report.hash}/?share=1`} className="flex-1 sm:flex-none flex items-center text-center text-cyan-400 hover:text-cyan-300 hover:underline">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                            </svg>
                            Share URL
                          </Link>
                          )}
                        
                        <Link to={`/reports/report/${report.hash}/?share=1`} className="mt-0.5 text-gray-400 hover:text-gray-300 text-sm flex items-center text-center mt-0 hover:underline">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                          </svg>
                          {report.shared_report_views} View{report.shared_report_views === 1 ? '' : 's'}
                        </Link>
                      </div>
                      <Link to={`/reports/report/${report.hash}/`} className="flex-1 sm:flex-none w-28 text-center ml-6 text-sm font-semibold py-1 px-3 rounded-md hover:opacity-90 border text-white bg-cyan-600 border-transparent">
                        <div>View Results</div>
                        <div className="text-gray-200 text-sm font-normal">{getReportResultsTitle(report)}</div>
                      </Link>
                    </div>
                  </div>
                  
                </ErrorBoundary>
              </Link>
            ))}
          </div>
        ) : (
          <Link className="opacity-75 hover:opacity-100 cursor-pointer border-2 border-dashed border-gray-700 p-4 h-32 w-full rounded-lg flex flex-col items-center justify-center"
            // onClick={() => document.getElementById('keyword-search').focus()}
            to="/reports/info/"
          >
            <h3>No AI reports yet {debouncedSearchTerm || typeOption !== 'All' ? `for this filter` : ''}</h3>
            <div className="text-sm opacity-75 mt-2 text-center">
              Make any search, then go to the "Patterns" or "Ask" tab to make an AI report
            </div>
          </Link>
        )}
      </div>
      {reports.length < totalCount ? (
        <div className="flex my-4">
          <button disabled={loading} onClick={() => {
            setLimit(limit + limitIncrement);
          }} className="text-gray-400 hover:text-gray-300 text-sm hover:underline mx-auto">
            {loading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      ) : ''}

      <ModalRoute
        path={`/reports/report/:hash/`}
        parentPath={`/reports/`}
        component={ReportModal}
        props={{
          reports,
          getReportTitle,
          getReportType,
          getReportResultsTitle,
          closeModal: () => history.push('/reports/')
        }}
      />
      <ModalContainer />
    </div>
  )
};

export default ReportsList;