import React, { useState } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import ErrorBoundary from '../common/errorboundary.jsx';

import { styleKeyword, findKeywordsAndTruncate, textContainsKeyword, cleanHtml } from '../../utils/text';
import { splitKeywords } from '../../utils/reddit';
import { timeStampToEasyTime } from '../../utils/time';
import { trackEvent } from '../../utils/tracking';
import { SUGGESTIONS } from '../audiences/constants/suggestions';

import { searchesService } from '../../services/searches';

import SubmissionTypeIcon from "./submissiontypeicon";
import SubredditPopover from "./subredditpopover";
import RedditorPopover from "./redditorpopover";
import ConversationListPopover from '../conversations/conversationlistpopover'


const ResultRowSubmission = ({
  item, location, keyword, hideDetails,
  // bgClass, hoverBgClass,
  textClass, submissionsUrl,
  showCloseOnCurrent, inNested,
  redirected,
  isSelected, toggleSelected,
  parentListElement, className,
  showAiMatches, showSubcategory,
}) => {
  const history = useHistory();
  const [bookmarkModalOpen, setBookMarkModalOpen] = useState(false);
  const [viewed, setViewed] = useState(item.viewed);
  const isLoading = !Object.keys(item).includes('redirected');

  // since user can save convo from this component, we have an updated state here
  const [savedListsUpdated, setSavedListsUpdated] = useState(false);
  const [updatedSavedLists, setUpdatedSavedLists] = useState([]);

  const rootUrl = submissionsUrl || '/reddit/submission/'; // mostly shown on reddit page, but potentially on saved search as well
  const isCurrent = location && location.pathname === `${rootUrl}${item.id}/`;

  // find matching keyword (if exists, also handle list)
  // TODO: match more than the first one?
  var matchingKeyword = null;
  if ((item.title || item.body) && keyword){
    splitKeywords(keyword).forEach(k => {
      if (!matchingKeyword && (textContainsKeyword(item.title, k) || textContainsKeyword(item.body, k))){
        matchingKeyword = k;
      }
    })
  }
  // if its an AI metch, use that as the matching keyword
  if (showAiMatches && item.ai_match && item.ai_match.match_snippet){
    matchingKeyword = item.ai_match.match_snippet;
  }

  // either populated from the parent, or updated here via the modal
  const savedLists = savedListsUpdated ? updatedSavedLists : item.saved_conversations || [];

  const scrollIntoView = () => {
    // after clicking on item, scroll to it (only if in a nested view)
    if (!inNested) return;

    // TODO: it used to be fine without having to do this. when you hit a nested item, it would just open (and closing one above it didn't affect scroll)
    setTimeout(() => {
      document.getElementById(`submission-${item.id}`).scrollIntoView({
          behavior: 'smooth'
      })
    }, 250)
  }

  const toggleOpen = (item) => {
    // if open, closes. if closed, navigate to open
    const newUrl = isCurrent ? `${rootUrl}${location.search}` : `${rootUrl}${item.id}/${location.search}`;
    history.push(newUrl);
    // setViewed(true);
    scrollIntoView();
  }

  const getThemeIcon = (slug) => {
    const theme = SUGGESTIONS.find(s => s.slug === slug);
    if (theme){
      return <theme.icon className="h-5 w-5 mr-1"/>
    }
    return '';
  }

  return (
    <li id={`submission-${item.id}`}
      className={`${className || ''} group relative pl-2 py-4 border-l-4 ${isCurrent ? 'border-gray-200' : 'border-transparent'} hover:${isCurrent ? 'border-gray-200' : 'border-not-gray-700'}`}
    >
      <ErrorBoundary>
        <div className="flex space-x-4">
          {/* left sidebar of user actions */}
          <div className="text-center flex flex-col gap-y-2 h-auto justify-between">
            <ReactTooltip id={`action-${item.id}`} effect="solid" place="right" backgroundColor="white" textColor="black"/>
                
            {/* checkbox if we're allowing for a select toggle */}
            {toggleSelected ? (
              <div className="w-6 flex-shrink-0 text-gray-400 text-center">
                <div className={`block mx-auto relative`}>
                  <input type="checkbox" value={isSelected} checked={isSelected} onChange={(e) => {
                      toggleSelected();
                    }}
                    className="h-5 w-5 ml-0 rounded-sm cursor-pointer border bg-gray-700 border-gray-600 focus:outline-none focus:ring-0 checked:bg-not-green-500 active:bg-not-green-500"
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}
                    className={`${isSelected ? 'hidden' : ''} hidden group-hover:block absolute top-1.5 left-1 h-4 w-4 cursor-pointer`}
                    onClick={() => toggleSelected()}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
            ) : ''}

            {isLoading ? (
              <svg className="animate-spin h-5 w-6 text-white opacity-25" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <React.Fragment>
                {/* set of icons/actions related to a user's activity on submission */}
                <ReactTooltip id={`bookmarks-${item.id}`} effect="solid" place="right" backgroundColor="white" textColor="black"/>
            
                <ConversationListPopover
                  // onSelect={(s) => console.log("selected", s)}
                  selectedIds={[item.id]} selectedType={'submission'}
                  currentLists={savedLists}
                  getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${item.id}`)}
                  positions={['bottom', 'right', 'top', 'left']}
                  onAdd={(list) => {
                    setUpdatedSavedLists([...savedLists, list])
                    setSavedListsUpdated(true)
                  }}
                  onRemove={(list) => {
                    setUpdatedSavedLists(savedLists.filter(s => s !== list))
                    setSavedListsUpdated(true)
                  }}
                  className={`w-6 relative ${savedLists && savedLists.length > 1 ? '' : 'h-6'}`}
                >
                  {savedLists.length === 0 ? (
                    <span data-for={`bookmarks-${item.id}`} data-tip="Bookmark this conversation" className="h-6 relative text-gray-500 hover:text-gray-200">
                      <SubmissionTypeIcon type={'bookmark'} className="mx-auto self-center h-6 w-6 mx-auto cursor-pointer" fill={'rgba(8, 145, 178,0.15)'}/>
                      <div className="hidden absolute w-6 h-6 top-0.5 text-center font-semibold text-xs text-gray-200">
                        +
                      </div>
                    </span>
                  ) : (
                    <div className="flex flex-col gap-y-2">
                      {savedLists.map(s => (
                        <span className="h-6" key={s.hash} data-for={`bookmarks-${item.id}`} data-tip={`Bookmarked: ${s.saved_search_name}`}>
                          <SubmissionTypeIcon type={'bookmark'} stroke={s.saved_search_color} fill={`${s.saved_search_color}75`} className="mx-auto self-center h-6 w-6 mx-auto cursor-pointer text-gray-400 hover:text-gray-200" />
                        </span>
                      ))}
                    </div>
                  )}
                </ConversationListPopover>

              </React.Fragment>
            )}

            {/* expand/contract always at bottom. not showing to keep clean*/}
            {/*{inNested ? (
              <div className="mt-auto w-6 text-center flex-shrink-0 text-gray-400 hover:text-gray-200 text-center cursor-pointer"
                onClick={() => toggleOpen(item)}
                data-for={`action-${item.id}`}
                data-tip={!isCurrent ? "Expand" : ''}
              >
                {isCurrent ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-5 mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-5 mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                )}
              </div>
            ) : ''}*/}
            
          </div>

          {/* main contents */}
          <div className="min-w-0 space-y-3 flex flex-col h-auto w-full">
            <div className="block max-w-full w-full mb-auto">
              <h2 className="text-base font-medium">
                <div className="flex">
                  <Link className="" to={isCurrent ? `${rootUrl}${location.search}` : `${rootUrl}${item.id}/${location.search}`}
                    onClick={() => {
                      scrollIntoView();
                      setViewed(true);
                    }}
                  >
                    <span className={`containsKeywords doubleLineClip ${item.is_removed || item.is_deleted ? '' : ''}`}
                      dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(item.title, matchingKeyword))}}
                    ></span>
                  </Link>

                  {item.over_18 ? (
                    <span className="h-6 inline-flex items-center flex-grow-0 ml-2 px-1.5 py-0.5 rounded-md text-xs font-medium bg-gray-700 text-gray-100">
                      18+
                    </span>
                  ) : ''}

                  {item.is_removed ? (
                    <span className="h-6 inline-flex items-center flex-grow-0 ml-2 px-1.5 py-0.5 rounded-md text-xs font-medium bg-gray-700 text-gray-100">
                      Removed
                    </span>
                  ) : ''}

                  {item.is_deleted ? (
                    <span className="h-6 inline-flex items-center flex-grow-0 ml-2 px-1.5 py-0.5 rounded-md text-xs font-medium bg-gray-700 text-gray-100">
                      Deleted
                    </span>
                  ) : ''}
                </div>
              </h2>
              
              {!item.is_deleted && !item.is_removed ? (
                <Link to={isCurrent ? `${rootUrl}${location.search}` : `${rootUrl}${item.id}/${location.search}`}
                  className="text-sm mt-2 text-gray-400 containsKeywords doubleLineClip"
                  onClick={() => {
                    scrollIntoView();
                    setViewed(true);
                  }}
                  dangerouslySetInnerHTML={{__html: matchingKeyword ? cleanHtml(styleKeyword(
                    findKeywordsAndTruncate(item.body, matchingKeyword),
                    matchingKeyword)) : cleanHtml(item.body)}}>
                </Link>
              ) : ''}

              {showAiMatches && item.ai_match ? (
                <div className="text-sm mt-2 text-green-400 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 mr-2 flex-shrink-0">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z" />
                  </svg>
                  <span data-for={`action-${item.id}`} data-tip={item.ai_match.reason}>AI-verified match ({item.ai_match.confidence_score}/5)</span>
                </div>
              ) : ''}
            </div>
    
            {/* info about this submission specifically */}
            {!hideDetails ? (
              <div className="flex items-center flex-wrap w-full">
                <div className="relative flex items-center space-x-1 sm:space-x-2 text-gray-600 text-center ">
                      
                  <a target="_blank" href={`https://www.reddit.com${item.link}`}
                    className="text-sm text-gray-500 font-medium flex"
                    data-for={`action-${item.id}`}
                    data-tip={`${item.is_removed ? 'Removed ' : item.is_deleted ? 'Deleted ' : 'Reddit '}${item.type} submission`}
                  >
                    {item.is_removed || item.is_deleted ? (
                      <SubmissionTypeIcon type={'removed'} className="self-center flex-shrink-0 h-4 w-5"/>
                    ) : (
                      <SubmissionTypeIcon type={item.type} className="h-5 w-5 mx-auto"/>
                    )}
                  </a>
                  <span aria-hidden="true">&middot;</span>

                  
                  <span className="text-sm text-gray-500 font-medium flex">
                    <svg className="self-center flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>{item.score}
                  </span>
                  <span aria-hidden="true">&middot;</span>
                  <span className="text-sm text-gray-500 font-medium flex">
                    
                    <svg className="self-center flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>{item.num_comments}
                  </span>
                  <span aria-hidden="true">&middot;</span>
                  
                  <SubredditPopover name={item.subreddit_name} openOnClick={true} openOnHover={true}
                    getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${item.id}`)}
                    positions={['right', 'left', 'top', 'bottom']}
                  >
                    <span className="text-sm text-gray-500 hover:text-white font-medium truncate">
                      {item.subreddit_name}
                    </span>
                  </SubredditPopover>

                  {showSubcategory && item.matching_subcategories && item.matching_subcategories.length ? (
                    <React.Fragment>
                      <span aria-hidden="true">&middot;</span>
                      <span className="text-sm text-gray-500 font-medium truncate flex items-center">
                        {getThemeIcon(item.matching_categories[0])}
                        {item.matching_subcategories.join(', ')}
                      </span>
                    </React.Fragment>
                  ) : ''}

                  {redirected || item.redirected || viewed || item.viewed ? (
                    <span aria-hidden="true">&middot;</span>
                  ) : ''}

                  {/* set of icons/actions related to a user's activity on submission */}
                  <ReactTooltip id={`icon-${item.id}`} effect="solid" place="right" backgroundColor="white" textColor="black"/>
            
                  {redirected || item.redirected ? (
                    <span className="w-5 mx-auto text-gray-500 font-medium flex"
                     data-for={`icon-${item.id}`} data-tip="You've clicked out to this submission on Reddit"
                    >
                      <SubmissionTypeIcon type={'clicked'} className="self-center h-6 w-5 mx-auto opacity-80 hover:opacity-100"/>
                    </span>
                  ) : viewed || item.viewed ? (
                    <span className="w-5 mx-auto text-gray-500 font-medium flex"
                      data-for={`icon-${item.id}`} data-tip="You've viewed this submission on GummySearch"
                    >
                      <SubmissionTypeIcon type={'viewed'} className="self-center flex-shrink-0 h-6 w-5 opacity-80 hover:opacity-100"/>
                    </span>
                  ) : ''}
                </div>
                <div className="relative ml-auto flex-col flex-shrink-0 items-end space-y-3">
                  <div className="flex text-gray-500 text-sm space-x-2">
                    <RedditorPopover name={item.author} openOnClick={true} openOnHover={true}
                      getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${item.id}`)}
                      positions={['left', 'right', 'top', 'bottom']}
                    >
                      <span className="hidden sm:block text-gray-500 hover:text-white">{item.author}</span>
                    </RedditorPopover>
                    <span className="hidden sm:block" aria-hidden="true">&middot;</span>
                    <span>{timeStampToEasyTime(item.timestamp_utc)}</span>
                  </div>
                </div>

              </div>
            ) : ''}
          </div>
          
        </div>
      </ErrorBoundary>
    </li>
  );
}

export default ResultRowSubmission;

