import React, { useState } from "react";
import {
  Link,
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

const ReportsInfoModal = ({ closeModal, history, currentUser }) => {
  

  return (
    <Modal close={closeModal} widthClass={'max-w-5xl'}>
      <Helmet><title>Reports | Info</title></Helmet>
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          AI Reports
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">

        {/*<div className="mt-4">
          <p>
            Welcome to your conversations page. Here you can find posts or comments you've saved to lists for future reference, as well as keywords you are tracking on Reddit.
          </p>
        </div>*/}

        {/*{isEffectiveProSubscriber ? (
          <div className="mt-4">
            <div className="opacity-50 mb-1">Advanced Search for Reddit</div>

            <div className="sm:flex space-x-4">
              <div className="flex-1">
                <p>
                  Use the conversations feature to search Reddit for keywords related to your business. You may search all Reddit submissions/comments, or target an Audience or specific subreddit.
                </p>
                <p className="mt-2">Advanced Search for Reddit is available to GummySearch Pro users only.</p>
              </div>
              <div className="flex-1 mt-2 sm:mt-0">
                <iframe width="100%" height="180px" src="https://www.youtube.com/embed/of1l75X1wys" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        ) : ''}*/}


        <div className="mt-4">
          <div className="opacity-50 mb-1 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 opacity-50" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
            </svg>
            Patterns AI
          </div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p>
                GummySearch can analyze for common patterns in Reddit submissions/comments.
              </p>
              <p className="mt-2">
                To make a patterns report, go to your audience and make either a keyword search, topic search, or theme search, then press the "Patterns" tab and analyze the patterns in these set of results.
              </p>
              <p className="mt-2">
                GummySearch will analyze the patterns in the results and give you a summary of the most common patterns. You can share/download this report.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/2Jxs5MsHCtY" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 opacity-50" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
            Ask AI
          </div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p className="mt-2">
                Ask a question to your audience and receive a relevant and recent answer with citations from Reddit.
              </p>
              <p className="mt-2">
                To make an Ask report, go to your audience and make either a keyword search, topic search, or theme search, then press the "Ask" tab and type a question you would like answered.
              </p>
              <p className="mt-2">
                GummySearch will use AI to answer your question and give you a summary of the most relevant Reddit posts/comments as citations. You can share/download this report.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
                <iframe src="https://drive.google.com/file/d/1Df8SeRIgR3FOPZhATXXDnjNgm45ZluE_/preview" width="100%" height="150" allow="autoplay"></iframe>
            </div>
          </div>
        </div> 

      </div>


    </Modal>
  );
};

export default ReportsInfoModal;

