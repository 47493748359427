import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import SubredditPopover from "../reddit/subredditpopover";
import RedditorPopover from "../reddit/redditorpopover";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";
import ConversationListPopover from '../conversations/conversationlistpopover'

import { searchesService } from '../../services/searches';

import { timeStampToEasyTime } from '../../utils/time';
import { cleanHtml } from '../../utils/text';
import { styleKeyword, findKeywordsAndTruncate, capitalizeFirst, slugify } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';


const Cluster = ({ c, isFirst, results, viewOption, getPopoverParentElement }) => {
  // const [expanded, setExpanded] = useState(isFirst ? true : false);
  const [expanded, setExpanded] = useState(false);
  const instancesToShow = expanded || (viewOption === 'full') ? c.items : [];
  const resultType = c.items[0] && c.items[0]['item'] && c.items[0]['item']['type'] === 'reddit-comment' ? 'comments' : 'submissions';
  const trackRedirect = true;
  const countToPreview = 3;

  // useEffect(() => {
  //   if (viewOption === 'full'){
  //     setExpanded(true);
  //   }
  // }, [viewOption])

  // get scores for whole cluster
  const stats = {
    'score': results.map(r => r.score).reduce((partialSum, a) => partialSum + a, 0),
    'num_comments': results.map(r => r.num_comments).reduce((partialSum, a) => partialSum + a, 0),
  }

  const scrollIntoView = (c) => {
    // after clicking on item, scroll to it (only if in a nested view)
    // if (!inNested) return;

    // TODO: it used to be fine without having to do this. when you hit a nested item, it would just open (and closing one above it didn't affect scroll)
    setTimeout(() => {
      if (!document.getElementById(`cluster-${c.name}`)) return;
      document.getElementById(`cluster-${c.name}`).scrollIntoView({
          behavior: 'smooth'
      })
    }, 100)
  }


  // renders a component for the cluster item
  const ClusterItem = ({ i, resultType }) => {
    // get the result based on this item's ID and results we have
    const result = results.filter(r => r.id === i.item.id)[0];

    return (
      <div id={`cluster-item-${i.item.id}`}>
        {/* don't show title for the comments */}
        {resultType !== 'comments' && result && result.title ? (
          <React.Fragment>
            <div className="text-base flex w-full">
              {result.title.toLowerCase() === i.phrase.toLowerCase() ? (
                <div className="mr-auto text-base font-semibold">
                  {result.title} {/* no need to highlight */}
                </div>
              ) : (
                <div className="mr-auto text-base font-semibold containsKeywords"
                  dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(
                    result.title,
                    capitalizeFirst(i.phrase),
                    'bg-transparent border-b border-dashed border-cyan-500'))
                  }}
                />
              )}
            </div>
            {/*<div>"{i.phrase}"</div>*/}

          </React.Fragment>
        ) : ''}

        {/* Render metadata about these results */}
        {result ? (
          <div className="text-xs text-gray-500">
            {resultType === 'submissions' ? (
              <div className="text-sm text-gray-400 containsKeywords tripleLineClip mb-1"
                // dangerouslySetInnerHTML={{__html: cleanHtml(result.body)}}
                dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(
                  findKeywordsAndTruncate(result.body, i.phrase, 300),
                  capitalizeFirst(i.phrase),
                  'bg-transparent border-b border-dashed border-cyan-500 text-gray-200'))
                }}
              />
            ) : resultType === 'comments' ? (
              <div className="text-sm text-gray-400 containsKeywords tripleLineClip mb-1"
                // dangerouslySetInnerHTML={{__html: cleanHtml(result.body)}}
                dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(
                  findKeywordsAndTruncate(result.body, i.phrase, 300),
                  capitalizeFirst(i.phrase),
                  'bg-transparent border-b border-dashed border-cyan-500 text-gray-200'))
                }}
              />
            ) : (
              <div className="text-sm text-gray-400 mb-1">
                Unknown type...
              </div>
            )}

            <div className="flex items-center flex-wrap w-full">
              <div className="relative flex items-center space-x-1 sm:space-x-2 text-gray-600 text-center ">
                <span className="text-sm text-gray-500 font-medium flex">
                  <SubmissionTypeIcon type="arrow-up" className="self-center flex-shrink-0 h-4 w-5" />
                  {result.score}
                </span>
                {result.num_comments ? (
                  <React.Fragment>
                    <span aria-hidden="true">&middot;</span>
                    <span className="text-sm text-gray-500 font-medium flex">
                      <SubmissionTypeIcon type="comment" className="self-center flex-shrink-0 h-4 w-5" />
                      {result.num_comments}
                    </span>
                  </React.Fragment>
                ) : ''}
                <span aria-hidden="true">&middot;</span>
                <SubredditPopover name={result.subreddit_name} openOnClick={true} openOnHover={true}
                  // getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${result.id}`)}
                  positions={['right', 'left', 'top', 'bottom']}
                >
                  <span className="text-sm text-gray-500 hover:text-white font-medium truncate">
                    {result.subreddit_name}
                  </span>
                </SubredditPopover>

                <span aria-hidden="true">&middot;</span>
                <span className="text-sm font-medium flex">
                  <a href={`https://www.reddit.com${result.link}`} target="_blank" rel="noreferrer" className="cursor-pointer text-gray-500 hover:text-white"
                    onClick={() => {
                      if (trackRedirect){
                        trackEvent("ExternalRedirect", {
                          "platform": "Reddit",
                          "type": "SubmissionSource",
                          "platform_id": result.id,
                          "title": result.title,
                          // "keyword": keyword,
                        });
                        searchesService.saveRedirect("RSU", result.id, result.title);
                      }
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                  </a>
                </span>
              </div>
              <div className="relative ml-auto flex-col flex-shrink-0 items-end space-y-3">
                <div className="flex text-gray-500 text-sm space-x-2">
                  <RedditorPopover name={result.author} openOnClick={true} openOnHover={true}
                    // getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${result.id}`)}
                    positions={['left', 'right', 'top', 'bottom']}
                  >
                    <span className="hidden sm:block text-gray-500 hover:text-white">{result.author}</span>
                  </RedditorPopover>
                  <span className="hidden sm:block" aria-hidden="true">&middot;</span>
                  <span>{timeStampToEasyTime(result.timestamp_utc)}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-xs opacity-50">
            Fetching {resultType}...
          </div>
        )}
      </div>
    )
  }


  return (
    <div key={`${c.name}`} id={`cluster-${c.name}`}
      className={`flex text-base w-full p-4 rounded-md bg-gray-800 ${expanded || (viewOption === 'full') ? '' : 'cursor-pointer hover:bg-gray-700'}`}
      onClick={() => {
        if (!expanded){
          setExpanded(true)
          scrollIntoView(c);
        }
      }}
    >
      {/*<ReactTooltip id={`${slugify(c.name)}-cluster`} place="top" effect="solid" backgroundColor="white" textColor="black" />*/}
              
      <div className="text-lg flex-shrink-0 flex justify-top items-top">
        <div className={` ${c.items.length > 1 ? 'opacity-100' : 'opacity-50'}`}>{c.items.length}</div>
        {c.items.length > 1 ? (
          <div className="ml-2 pt-1 cursor-pointer opacity-50 hover:opacity-75 cursor-pointer" onClick={() => setExpanded(!expanded)}>
            {!expanded ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            )}
          </div>
        ) : ''}
      </div>

      <div className="flex-grow ml-2 sm:ml-4 text-base sm:text-lg">
        <div className="flex">
          <div className="flex-shrink-0 mr-2">
            <span role="img">{c.emoji}</span>
          </div>
          <div className="font-semibold cursor-pointer flex-grow truncate mr-auto"
            onClick={() => {
              if (expanded){
                setExpanded(false)
              }
            }}
          >
            {c.name}
            {/*<div className="text-xs line-through opacity-75">{c.old_name}</div>*/}
          </div>


          <div className="flex-shrink-0 ml-2 relative flex items-center space-x-1 sm:space-x-2 text-gray-600 text-center text-xs sm:text-sm">
            <span className="text-gray-500 font-medium flex">
              <SubmissionTypeIcon type="arrow-up" className="self-center flex-shrink-0 h-4 w-5" />
              {stats.score}
            </span>
            {stats.num_comments ? (
              <React.Fragment>
                <span className="" aria-hidden="true">&middot;</span>
                <span className="text-gray-500 font-medium flex">
                  <SubmissionTypeIcon type="comment" className="self-center flex-shrink-0 h-4 w-5" />
                  {stats.num_comments}
                </span>
              </React.Fragment>
            ) : ''}
          </div>
        </div>

        {expanded || (viewOption === 'full') ? (
          <React.Fragment>
            <div className="space-y-4 mt-2 pt-2 border-t border-gray-700">
        
              {instancesToShow.map(i => (
                <ClusterItem i={i} key={slugify(i.phrase)} resultType={resultType} />
              ))}
            </div>

            {c.items && c.items.length ? (
              <div className="w-full flex justify-start space-x-4 mt-4">
                <ConversationListPopover
                  // onSelect={(s) => console.log("selected", s)}
                  selectedIds={c.items.map(i => i.item.id)} selectedType={resultType.substring(0, resultType.length - 1)}
                  currentLists={[]}
                  getPopoverParentElement={getPopoverParentElement}
                  positions={['top', 'right', 'bottom', 'left']}
                  className="relative"
                  // onAdd={(list) => {
                  //   // setUpdatedSavedLists([...savedLists, list])
                  //   // setSavedListsUpdated(true)
                  // }}
                  // onRemove={(list) => {
                  //   // setUpdatedSavedLists(savedLists.filter(s => s !== list))
                  //   // setSavedListsUpdated(true)
                  // }}
                >
                  <div type="button"
                    className="flex cursor-pointer items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50 flex-shrink-0">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                    </svg>

                    Bookmark {c.items.length} {resultType}
                  </div>
                </ConversationListPopover>

                <Link to={`/reddit/?type=${resultType}&ids=${c.items.map(i => i.item.id).join(",")}`}
                  type="button"
                  className="cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                  </svg>
                  Browse {c.items.length} {resultType}
                </Link>

                {/*{stats.num_comments ? (
                  <Link to={`/reddit/?type=submission-replies&ids=${c.items.map(i => i.item.id).join(",")}`}
                    type="button"
                    className="cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    Browse {stats.num_comments} {stats.num_comments === 1 ? 'reply' : 'replies'}
                  </Link>
                ) : ''}*/}

                
              </div>
            ) : 'No items in this cluster...'}
            
          </React.Fragment>
        ) : viewOption === 'preview' ? (
          <div className="text-xs text-gray-400">
            <ul className="pl-4 ml-0.5">
              {c.items.filter(i => i.item).filter((r, index) => index <= (countToPreview - 1)).map((i, index) => (
                <li key={i.item.id} className="flex items-top mt-0.5">
                  <div className="mr-1">-</div>
                  <div className="singleLineClip">
                    <span>{capitalizeFirst(i.phrase)}</span>
                    {index == (countToPreview - 1) && c.items.length > countToPreview ? (
                      <span className="ml-2 opacity-60">+ {c.items.length - countToPreview} more</span>
                    ) : ''}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : ''}
        
      </div>
    </div>
  );
}

export default Cluster;
