import React, { useState, useEffect } from "react";
import {
  Link, Switch, Route
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Toggle from 'react-toggle'
import { CheckIcon } from '@heroicons/react/outline'

import ResultRowSubmission from "../reddit/resultrowsubmission";
import ResultRowComment from "../reddit/resultrowcomment";
import SubmissionContents from "../reddit/submissioncontents";
import CommentContents from "../reddit/commentcontents";
import LoaderFancy from "../common/loaderfancy";
import ErrorBoundary from "../common/errorboundary";
import PropsRoute from "../common/propsroute";
import SelectPopover from "../common/selectPopover";
import SaveKeywordInfoModal from "../conversations/saveKeywordInfoModal"

import { redditService } from '../../services/reddit';
import { savedSearchOperations } from "../../redux/saved"
import { trackEvent } from '../../utils/tracking';
import { toQueryString } from '../../utils/urls';
import { canAskToSaveKeyword } from '../../utils/keywords';


// This is a set of reddit results (comments or submissions) with some nested navigation
const NestedResults = ({
  results,
  keyword,
  fetching,
  baseUrl,
  location,
  history,
  currentUser,
  showHeader,
  showAiMatches,
  sortOption, setSortOption, sortOptions,
  limitOption, setLimitOption, limitOptions,
  subcategoryOption, setSubcategoryOption, subcategoryOptions,
  inlineScroll,
  selectedIds = [], setSelectedIds, toggleSelectedId,
}) => {
  // state
  // keep a list of ids we redirected (to visually mark them)
  const [redirectedIds, setRedirectedIds] = useState([]);
  const [showSaveKeywordModal, setShowSaveKeywordModal] = useState(false);
  const [trackKeywordButton, setTrackKeywordButton] = useState(null);
  const [filterToAiMatches, setFilterToAiMatches] = useState(showAiMatches && results && results.filter(r => r.ai_match).length > 0);
  const resultType = results && results[0] && results[0]['_meta']['type'] || '';
  var title = 'Result'
  if (resultType === 'reddit-submission'){
    title = 'Submission'
  } else if (resultType === 'reddit-comment'){
    title = 'Comment'
  }

  useEffect(() => {
    if (showAiMatches && results && results.filter(r => r.ai_match).length > 0){
      setFilterToAiMatches(true);
    }
  }, [results]);

  
  // actions
  const markRead = (id) => {
    setRedirectedIds([...redirectedIds, id])
  }


  // asks user if they want to save this keyword to be notified of later
  const askToSaveSearch = () => {
    // make sure we're allowed to ask (single keyword and we haven't asked this modal for it yet)
    if (!canAskToSaveKeyword(keyword)) return;
    
    // only show if we have the keyword button on the screen
    const trackKeywordButton = document.getElementById('track-keyword-button') && document.getElementById('track-keyword-button').parentElement;
    if (trackKeywordButton && !trackKeywordButton.classList.contains('tracking')){
      setTrackKeywordButton(trackKeywordButton); // set so that we have it in the modal
      setShowSaveKeywordModal(true); // open the modal
    }
  }


  // filter results if there are ai matches available and filter is on
  const resultsToShow = showAiMatches && filterToAiMatches ? results.filter(r => r.ai_match) : results;

  return (
    <ErrorBoundary>
      {fetching ? (
        <LoaderFancy />
      ) : results && results.length !== 0 ? (
        <React.Fragment>
          <div id="nested-results-scroll" className={` ${inlineScroll ? '' : 'overflow-auto h-full'}`}>

            {showHeader ? (
              <div className="pt-4 mx-4 no-border-b no-border-gray-800">
                <h3 className="flex-1 flex text-sm items-center">
                  <span className="text-gray-300 font-semibold">{title}{results.length !== 1 ? 's' : ''}</span>
                  
                  <p className="ml-2 text-xs text-gray-300 truncate font-semibold">
                    {fetching ? (
                      <span>
                        <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </span>
                    ) : selectedIds && selectedIds.length ? (
                      <span>
                        {selectedIds.length} selected
                        <span className="ml-2 text-cyan-500 font-semibold cursor-pointer opacity-80 hover:opacity-100"
                          onClick={() => setSelectedIds([])}
                        >
                          clear
                        </span>
                      </span>
                    ) : (
                      <span className="opacity-50">{resultsToShow.length} {resultsToShow.length !== results.length ? ` / ${results.length}` : ''}</span>
                    )}
                  </p>

                  
                  <div className="ml-auto flex items-center space-x-4" disabled={fetching}>

                    {showAiMatches ? (
                      <div className="flex items-center rounded-md">
                        <div className="text-xs opacity-50 mr-2">AI Verified</div>
                        <Toggle
                          defaultChecked={filterToAiMatches}
                          className="toggle-darkmode"
                          icons={{
                            checked: <CheckIcon className="h-5 w-5"/>,
                            unchecked: null
                          }}
                          onChange={(e) => {
                            setFilterToAiMatches(e.target.checked ? true : false)
                          }}/>
                      </div>
                    ) : ''}

                    {results && results.length >= 100 && limitOptions && limitOption && setLimitOption ? (
                      <div className="ml-auto flex items-center">
                        <div className="text-xs opacity-50 mr-2">Count</div>
                        <SelectPopover
                          options={limitOptions}
                          currentOption={limitOption}
                          setCurrentOption={setLimitOption}
                          labelField={undefined}
                        />
                      </div>
                    ) : ''}
                    

                    {subcategoryOptions && subcategoryOptions.length && setSubcategoryOption ? (
                      <div className="ml-auto flex items-center">
                        <div className="text-xs opacity-50 mr-2">Category</div>
                        <SelectPopover
                          options={subcategoryOptions}
                          currentOption={subcategoryOption}
                          setCurrentOption={setSubcategoryOption}
                          labelField={'label'}
                          selectedLabelField={'name'}
                          includeClear={true}
                        />
                      </div>
                    ) : ''}
                    
                    {sortOptions && sortOption && setSortOption ? (
                      <div className="ml-auto flex items-center">
                        <div className="text-xs opacity-50 mr-2">Sort</div>
                        <SelectPopover
                          options={sortOptions}
                          currentOption={sortOption}
                          setCurrentOption={setSortOption}
                          labelField={undefined}
                        />
                      </div>
                    ) : ''}

                  </div>
                  
                </h3>
              </div>
            ) : ''}

            <ul className={`relative z-0`}>
              {resultsToShow.map(item => (
                <div className="border-b border-gray-800" key={item.id}>
                  {item._meta.type === 'reddit-submission' ? (
                    <React.Fragment>
                      <ResultRowSubmission key={item.id} item={item} location={location}
                        keyword={keyword} backend={item._meta.backend}
                        submissionsUrl={`${baseUrl}submission/`}
                        redirected={redirectedIds.includes(item.id)}
                        showCloseOnCurrent={true} inNested={true}
                        isSelected={selectedIds.includes(item.id)} toggleSelected={toggleSelectedId ? () => toggleSelectedId(item.id) : null}
                        parentListElement={document.getElementById(`nested-results-scroll`)}
                        showAiMatches={showAiMatches}
                        showSubcategory={subcategoryOptions && subcategoryOptions.length}
                        className="pl-5 pr-2"
                      />
                      <div className="border-l-4 border-gray-200 pl-4">
                        <Switch>
                          <PropsRoute path={`${baseUrl}submission/${item.id}/`}
                            component={SubmissionContents}
                            close={() => history.push(`${baseUrl}${location.search}`)}
                            keyword={keyword}
                            submission={item}
                            currentUser={currentUser}
                            onRedirect={() => {
                              markRead();
                              askToSaveSearch();
                            }}
                            topBorder={true} hideHeader={true}
                          />
                        </Switch>
                      </div>
                    </React.Fragment>
                  ) : item._meta.type === 'reddit-comment' ? (
                    <React.Fragment>
                      <ResultRowComment key={item.id} item={item} location={location}
                        keyword={keyword} backend={item._meta.backend}
                        commentsUrl={`${baseUrl}comment/`}
                        redirected={redirectedIds.includes(item.id)}
                        inNested={true}
                        isSelected={selectedIds.includes(item.id)} toggleSelected={toggleSelectedId ? () => toggleSelectedId(item.id) : null}
                        parentListElement={document.getElementById(`nested-results-scroll`)}
                        showAiMatches={showAiMatches}
                        className="pl-5 pr-2"
                      />
                      <div className="border-l-4 border-gray-200 pl-4">
                        <Switch>
                          <PropsRoute path={`${baseUrl}comment/${item.id}/`}
                            component={CommentContents}
                            close={() => history.push(`${baseUrl}${location.search}`)}
                            keyword={keyword}
                            comment={item}
                            onRedirect={markRead}
                            currentUser={currentUser}
                            topBorder={true} hideHeader={true}
                          />
                        </Switch>
                      </div>
                    </React.Fragment>
                  ) : ''}
                </div>
              ))}

              {resultsToShow.length === 0 ? (
                <div className="p-6 bg-gray-800 rounded-lg m-4 border border-solid border-gray-700 text-white">
                  <div className="">
                    <h3 className="text-lg leading-6 font-medium">
                      No results for this filter.
                    </h3>
                  </div>
                </div>
              ) : ''}
            </ul>
          </div>

          {showSaveKeywordModal ? (
            <SaveKeywordInfoModal
              keyword={keyword}
              closeModal={() => setShowSaveKeywordModal(false)}
              trackKeywordButton={trackKeywordButton}
            />
          ) : ''}

        </React.Fragment>
      ) : results && results.length === 0 ? (
        <div className="p-6 bg-gray-800 rounded-lg m-4 border border-solid border-gray-700 text-white">
          <div className="">
            <h3 className="text-lg leading-6 font-medium">
              No results at this time...
            </h3>

            {currentUser.env.psaw_warning ? (
              <div className="mt-2 max-w-xl text-sm flex items-top opacity-50">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2 flex-shrink-0">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>

                <p>{currentUser.env.psaw_warning}</p>
              </div>
            ) : ''}
          </div>
        </div>
      ) : ''}
    </ErrorBoundary>
  );
}

export default NestedResults;

