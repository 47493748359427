import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
  Link, Switch, Route
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import ErrorBoundary from "../common/errorboundary";
import Loader from "../common/loader";

import { redditService } from '../../services/reddit';
import { collectionsService } from '../../services/collections';

import { readableNumber } from '../../utils/text';
import { velocityToString } from '../../utils/text';
import { timeStampToEasyTime } from '../../utils/time';


// renders a collection (most likely of subreddits)
const TrackedKeywordRow = ({
  search,
  getCollectionNameFromHash,
  fetchingUnread,
  unreadCount,
  unreadAiMatchesCount,
  maxVelocity,
  maxPerformanceScore,
  maxEfficiencyScore,
  includeStats,
  baseUrl,
  hideAudienceLabel,
}) => {


  // calculate velocity of this saved search compared to others
  const velocity = search.stats && search.stats.recent_velocity;
  const velocityPercentWidth = Math.min(100 * (velocity / maxVelocity), 100);

  // performance score
  const performanceScore = search.stats && search.stats.performance;
  const performanceScorePercentWidth = Math.min(100 * (performanceScore / maxPerformanceScore), 100);
  const performanceScoreBuckets = {
    0: {'index': 1, 'label': 'Fine', 'color': 'text-yellow-500', 'bgColor': 'bg-yellow-500'},
    20: {'index': 2, 'label': 'Good', 'color': 'text-green-500', 'bgColor': 'bg-green-500'},
    50: {'index': 3, 'label': 'Great', 'color': 'text-green-500', 'bgColor': 'bg-green-500'},
    80: {'index': 4, 'label': 'Best', 'color': 'text-green-500', 'bgColor': 'bg-green-500'},
  }
  var performanceScoreDisplay = {'index': 0, 'label': 'Bad', 'color': 'text-red-500', 'bgColor': 'bg-red-500'};
  Object.keys(performanceScoreBuckets).forEach((key) => {
    if (performanceScorePercentWidth > key){
      performanceScoreDisplay = performanceScoreBuckets[key]
    }
  })

  // efficiency score
  const efficiencyScore = search.stats && search.stats.efficiency;
  const efficiencyScorePercentWidth = Math.min(100 * (efficiencyScore / maxEfficiencyScore), 100);
  const efficiencyScoreBuckets = {
    0: {'index': 1, 'label': 'Mostly noise', 'color': 'text-yellow-500', 'bgColor': 'bg-yellow-500'},
    20: {'index': 2, 'label': 'Occasional gem', 'color': 'text-yellow-500', 'bgColor': 'bg-yellow-500'},
    50: {'index': 3, 'label': "Works often", 'color': 'text-green-500', 'bgColor': 'bg-green-500'},
    80: {'index': 4, 'label': "Very efficient", 'color': 'text-green-500', 'bgColor': 'bg-green-500'},
  }
  var efficiencyScoreDisplay = {'index': 0, 'label': 'Not worth it', 'color': 'text-red-500', 'bgColor': 'bg-red-500'};
  Object.keys(efficiencyScoreBuckets).forEach((key) => {
    if (efficiencyScorePercentWidth > key){
      efficiencyScoreDisplay = efficiencyScoreBuckets[key]
    }
  })

  const getDescription = (search) => {
    // gets a string description based on the params

    var toReturn = ''
    if (search.params.keyword){
      toReturn += `"${search.params.keyword}"`
    } else if (search.params.usernames){
      toReturn += `All`
    } else if (search.params.sort){
      toReturn += `${search.params.sort}`
    } else {
      toReturn += `Unknown`
    }

    if (search.search_type === "RS"){
      toReturn += ` submissions`
    } else {
      toReturn += ` comments`
    }

    if (!hideAudienceLabel){
      toReturn += ` from `;
      if (search.params.collection){
        toReturn += `${getCollectionNameFromHash(search.params.collection)}`
      } else if (search.params.usernames){
        toReturn += `${search.params.usernames}`
      } else if (search.params.subreddits){
        toReturn += `${search.params.subreddits.split(',').join(', ')}`
      } else {
        toReturn += `anyone on Reddit`
      }
    }
    return toReturn
  }

  return (
    <Link to={fetchingUnread || unreadCount === 0 ? `${baseUrl}${search.hash}/all/` : `${baseUrl}${search.hash}/new/`} key={search.hash} className="rounded-md shadow-lg bg-gray-800 hover:bg-gray-700 text-white flex p-2 flex-col mb-2">
      <ErrorBoundary>
        <div className={`${includeStats ? 'sm:flex' : 'flex'} text-sm px-1`}>

          <div className="flex xl:flex-1 space-x-1 mr-4 sm:mb-0">
            <div className="mr-2 relative py-2">
              <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${unreadCount > 0 ? 'opacity-25' : 'opacity-25'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
              </svg>

              {fetchingUnread ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 absolute top-0 -right-1 animate-spin opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              ) : unreadCount > 0 ? (
                <React.Fragment>
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 absolute top-1 -right-2 ${unreadAiMatchesCount ? 'text-green-400' : 'text-teal-500'}`} fill={unreadAiMatchesCount ? '#34d399' : '#06b6d4'} viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span className="w-5 absolute top-1.5 -right-2 text-black text-center text-xs" style={{fontSize: '9px'}}>{unreadAiMatchesCount || unreadCount}</span>
                </React.Fragment>
              ) : ''}
            </div>
            
            <div>
              <div className="text-gray-100 text-base flex items-center h-full sm:h-auto">
                <ReactTooltip id={`search-icon-${search.hash}`} className="p-2" place="top" effect="solid" backgroundColor="white" textColor="black" />
                
                <div className="mr-1">{search.name}</div>

                {search.label ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}
                    data-for={`search-icon-${search.hash}`} data-tip={search.label}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                ) : ''}

                {search.notify && search.notify_instantly ? (
                  <svg className="h-4 w-4 ml-1 text-yellow-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    data-for={`search-icon-${search.hash}`} data-tip="Instant Alerts"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                  </svg>
                ) : !search.notify ? (
                  <svg className="h-4 w-4 ml-1 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    data-for={`search-icon-${search.hash}`} data-tip={'Not included in digest notifications'}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53" />
                  </svg>
                ) : ''}

                {search.match_criteria && (search.match_criteria.include || search.match_criteria.exclude) ? (
                  <svg className="h-4 w-4 ml-1 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    data-for={`search-icon-${search.hash}`} data-html={true}
                    data-tip={`Include: ${search.match_criteria.include || 'N/A'}<br/>Exclude: ${search.match_criteria.exclude || 'N/A'}`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                  </svg>
                ) : ''}  

              </div>
              <div className="hidden sm:flex items-center space-x-1 text-gray-500 text-sm">
                {getDescription(search)}
              </div>
            </div>
          </div>

          <div className={`flex xl:flex-1 ml-auto xl:ml-4`}>
            
            {!includeStats && search.stats && search.stats.track_days > 1 ? (
              <div className="ml-auto flex">

                <ReactTooltip id={`stats-icon-${search.hash}`} className="p-2" place="top" effect="solid" backgroundColor="white" textColor="black" />
                
                {/*{performanceScoreDisplay.index >= 3 ? (
                  <div className={`p-2 ${performanceScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Performance: ${performanceScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                    </svg>
                  </div>
                ) : performanceScoreDisplay.index >= 2 ? (
                  <div className={`p-2 ${performanceScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Performance: ${performanceScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                    </svg>
                  </div>
                ) : performanceScoreDisplay.index >= 1 ? (
                  <div className={`p-2 ${performanceScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Performance: ${performanceScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M10.5 8.25h3l-3 4.5h3" />
                    </svg>
                  </div>
                ) : (
                  <div className={`p-2 ${performanceScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Performance: ${performanceScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53" />
                    </svg>
                  </div>
                )}

                {efficiencyScoreDisplay.index >= 3 ? (
                  <div className={`p-2 ${efficiencyScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Efficiency: ${efficiencyScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                  </div>
                ) : efficiencyScoreDisplay.index >= 2 ? (
                  <div className={`p-2 ${efficiencyScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Efficiency: ${efficiencyScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                    </svg>
                  </div>
                ) : efficiencyScoreDisplay.index >= 1 ? (
                  <div className={`p-2 ${efficiencyScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Efficiency: ${efficiencyScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </div>
                ) : (
                  <div className={`p-2 ${efficiencyScoreDisplay.color}`} data-for={`stats-icon-${search.hash}`} data-tip={`Efficiency: ${efficiencyScoreDisplay.label}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </div>
                )}*/}
              </div>
            ) : (
              <div className="flex-1">
                
                {includeStats && search.stats  ? (
                  <div className="grid grid-cols-2 gap-x-4 gap-y-3">

                    <ReactTooltip id={`stats-info-${search.hash}`} className="p-2" place="top" effect="solid" backgroundColor="white" textColor="black" />
                               
                    {search.stats && search.stats.track_days > 1 ? (
                      <div className="">
                        <dt className="font-normal opacity-50 mb-0 flex items-center">
                          <span>Performance</span>
                          <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="Relative score for how many useful conversations you've found through this tracked keyword">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                        </dt>
                        {search.stats && search.stats.track_days > 1 ? (
                          <div className={`rounded-md bg-gray-600 text-white flex items-center items-stretch mt-1`}>
                            <div className="flex-1 bg-gray-700 rounded-sm relative flex-shrink-0">
                              <div className="text-xs absolute px-1 text-white opacity-75">{performanceScoreDisplay.label}</div>
                              <div className={`${performanceScoreDisplay.bgColor} rounded-sm p-0.5 h-4`} style={{width: performanceScorePercentWidth + '%'}}></div>
                            </div>
                          </div>
                        ) : <span className="opacity-25"></span>}
                      </div>
                    ) : ''}

                    {search.stats && search.stats.track_days > 1 ? (
                      <div className="">
                        <dt className="font-normal opacity-50 mb-0 flex items-center">
                          <span>Efficiency</span>
                          <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="Signal-to-noise ratio (high performance & low frequency => most efficent)">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                        </dt>
                        {search.stats && search.stats.track_days > 1 ? (
                          <div className={`rounded-md bg-gray-600 text-white flex items-center items-stretch mt-1`}>
                            <div className="flex-1 bg-gray-700 rounded-sm relative flex-shrink-0">
                              <div className="text-xs absolute px-1 text-white opacity-75">{efficiencyScoreDisplay.label}</div>
                              <div className={`${efficiencyScoreDisplay.bgColor} rounded-sm p-0.5 h-4`} style={{width: efficiencyScorePercentWidth + '%'}}></div>
                            </div>
                          </div>
                        ) : <span className="opacity-25"></span>}
                      </div>
                    ) : ''}
                                        
                    {/*<div className="">
                      <dt className="font-normal opacity-50 mb-0 flex items-center">
                        <span>Tracked Days</span>
                        <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="How many days you've been tracking this keyword">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </dt>
                      {search.stats ? (
                        <div className="">
                          {search.stats.track_days}
                        </div>
                      ) : <span className="opacity-25"></span>}
                    </div>*/}

                    <div className="">
                      <dt className="font-normal opacity-50 mb-0 flex items-center">
                        <span>Total Results</span>
                        <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="How many conversations match these filters">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </dt>
                      {search.stats ? (
                        <div className="">
                          {search.stats.total_count}
                        </div>
                      ) : <span className="opacity-25"></span>}
                    </div>


                    <div className="">
                      <dt className="font-normal opacity-50 mb-0 flex items-center">
                        <span>Frequency</span>
                        <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="How frequently this keyword is mentioned">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </dt>
                      {search.stats ? (
                        <div className={`rounded-md bg-gray-600 text-white flex items-center items-stretch mt-1`}>
                          <div className="flex-1 bg-gray-700 rounded-sm relative flex-shrink-0">
                            <div className="text-xs absolute px-1 text-white opacity-75">{velocity && velocityToString(velocity)}</div>
                            <div className="bg-gray-600 rounded-sm p-0.5 h-4" style={{width: velocityPercentWidth + '%'}}></div>
                          </div>
                        </div>
                      ) : <span className="opacity-25">Loading...</span>}
                    </div>

                    {search.stats && search.stats.track_days > 1 ? (
                      <div className="">
                        <dt className="font-normal opacity-50 mb-0 flex items-center">
                          <span>Your Views</span>
                          <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="How many matches you expanded to read fully">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                        </dt>
                        {search.stats ? (
                          <div className="">
                            {Math.max(search.stats.viewed_count, search.stats.recent_viewed)}
                          </div>
                        ) : <span className="opacity-25"></span>}
                      </div>
                    ) : ''}

                    {search.stats && search.stats.track_days > 1 ? (
                      <div className="">
                        <dt className="font-normal opacity-50 mb-0 flex items-center">
                          <span>Your Clicks</span>
                          <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="How many matches you clicked 'Reply' on to view on Reddit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </span>
                        </dt>
                        {search.stats ? (
                          <div className="">
                            {Math.max(search.stats.clicked_count, search.stats.recent_clicked)}
                          </div>
                        ) : <span className="opacity-25"></span>}
                      </div>
                    ) : ''}

                    

                    {/*<div className="">
                      <dt className="font-normal opacity-50 mb-0 flex items-center">
                        <span>Last Viewed</span>
                        <span className="ml-2" data-for={`stats-info-${search.hash}`} data-tip="Last time you checked results for this tracked keyword">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </dt>
                      {search.stats ? (
                        <div className="">
                           {search.last_viewed_at ? timeStampToEasyTime(search.last_viewed_at) : '--'}
                        </div>
                      ) : <span className="opacity-25"></span>}
                    </div>*/}
                  </div>
                ) : includeStats ? (
                  <div className="opacity-25 text-sm">
                    <div>No stats available yet.</div>
                    <div className="hidden xl:block">Interact with older results and check back again tomorrow :)</div>
                  </div>
                ) : ''}
              </div>
            )}
            
          </div>
        </div>
        
      </ErrorBoundary>
    </Link>
  )
}

export default TrackedKeywordRow;

