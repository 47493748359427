import React, { useState } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
// import { getParameterByName } from "../../utils/urls";


const PasswordReset = ({history, location, match, authenticated }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
  const [sentResetEmail, setSentResetEmail] = useState(null);

	const submitForm = (e) => {
		e.preventDefault();  // to not post to the current url
		setLoading(true);
    setError(null);

    const email = e.target.elements['email'].value;

		authService.forgotPassword(
			email,
			(response) => {
				setError(null);
        setLoading(false);
        setSentResetEmail(email);
			},
			(error, response) => {
				setError((response && response.email && response.email[0]) || 'could not reset password');
				setLoading(false);
			}
		)
	}

  return (
    <LayoutAuth title={sentResetEmail ? 'Please check your email.' : 'Enter the email address associated with your account.'}>
        <Helmet><title>Password Reset</title></Helmet>
      <form className="space-y-6" action="#" onSubmit={submitForm}>

      	{error ? (
      		<div className="bg-red-500 p-2 px-3 font-medium rounded-xs">
            {error}
          </div>
      	) : ''}

        {sentResetEmail ? (
          <div className="bg-green-500 p-2 px-3 font-medium rounded-sm">
            <div>We’ve sent you a link to reset your password.</div>
            <div>If you haven't received a link within 30s, that means you entered an email that does not exist in our system.</div>
          </div>
        ) : (
          <React.Fragment>
    	      <div>
              <label htmlFor="email" className="block uppercase text-sm opacity-75 font-medium">
                Email Address
              </label>
              <div className="mt-1">
                <input id="email" name="email" type="email" autoFocus autoComplete="email" placeholder="Email Address" required
                  className="appearance-none bg-gray-700 block w-full p-3 rounded-sm border-solid border-transparent shadow-sm focus:outline-none focus:ring-transparent focus:border-cyan-500"
                />
              </div>
            </div>

            <div className="sm:flex items-center justify-between sm:flex-row-reverse">
              <button type="submit" className="cursor-pointer w-full justify-center tracking-wider p-3 font-semibold rounded-sm shadow-sm bg-cyan-500 focus:outline-none"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Submit'}
              </button>
            </div>

            <div className="mt-6 text-sm">
              <div className="mb-2 mr-auto">
                <Link to="/login/" className="hover:underline text-right font-semibold mr-auto">Go back to login</Link>
              </div>
            </div>

          </React.Fragment>
        )}

	    </form>
    </LayoutAuth>
  );
}

export default PasswordReset;
